import React, { useState, useEffect, useCallback, useRef } from 'react';
import Chart from 'chart.js/auto';
import './OddsPage.css';
import StatTable from '../components/StatTable';
import Navbar from '../components/Navbar';
import HistoricalChart from '../components/HistoricalChart';
import zoomPlugin from 'chartjs-plugin-zoom';
import LineupsDisplay from '../components/LineupsDisplay';

Chart.register(zoomPlugin);

const OddsPage = ({
  updateLastUpdated,
  sport,
  favorites,
  setFavorites,
  lastUpdated,
  setSport,
  sidebarOpen,
  toggleSidebar,
  subscriptionType,
  filters,
  handleFilterChange,
  oddsData,
  filteredData,
  fetchOdds,
  applyFilters,
  sortData,
  visibleSportsbooks,

  
}) => {
  const [historicalData, setHistoricalData] = useState([]);
  const [dataFilter, setDataFilter] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [mlbStatsData, setMlbStatsData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const fetchedPlayersRef = useRef(new Set());
  const [playerStatsData, setPlayerStatsData] = useState([]);

  const [lineupsData, setLineupsData] = useState([]);
  const [filteredLineups, setFilteredLineups] = useState([]);
  const [isLineupsAvailable, setIsLineupsAvailable] = useState(false);
  const [wnbaStatsData, setWnbaStatsData] = useState([]); // Separate state for WNBA stats
  const [wnbaTeamStatsData, setWnbaTeamStatsData] = useState([]); // Separate state for WNBA team stats

  const safeFilteredData = Array.isArray(filteredData) ? filteredData : [];
  const [underdogData, setUnderdogData] = useState([]);

  const [nflStatsData, setNflStatsData] = useState([]);

  const fetchNflStats = async () => {
    try {
      const response = await fetch('/nfl_stats');
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setNflStatsData(data);
      } else {
        console.error(`Error fetching NFL stats: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching NFL stats:`, error);
    }
  };

  useEffect(() => {
    fetchNflStats();
  }, []);
  
  const fetchUnderdogData = useCallback(async () => {
    try {
      const response = await fetch(`/get_underdog_props?sport=${sport}`);
      if (response.ok) {
        const data = await response.json();
        console.log('underdog data',data)
        setUnderdogData(data);
      } else {
        console.error(`Error fetching Underdog data: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching Underdog data:`, error);
    }
  }, [sport]);

  useEffect(() => {
    fetchUnderdogData();
  }, [fetchUnderdogData]);


  useEffect(() => {
    fetchOdds();
  }, [fetchOdds, sport]);

  useEffect(() => {
    if (sport === 'wnba') {
      const fetchWNBAStats = async () => {
        try {
          const response = await fetch('/wnba_stats');
          if (response.ok) {
            const data = await response.json();
            setWnbaStatsData(data); // Set the fetched WNBA player stats data
          } else {
            console.error(`Error fetching WNBA player stats: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching WNBA player stats:`, error);
        }
      };

      const fetchWNBATeamStats = async () => {
        try {
          const response = await fetch('/wnba_team_stats');
          if (response.ok) {
            const data = await response.json();
            setWnbaTeamStatsData(data); // Set the fetched WNBA team stats data
          } else {
            console.error(`Error fetching WNBA team stats: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error fetching WNBA team stats:`, error);
        }
      };

      fetchWNBAStats();
      fetchWNBATeamStats();
    }
  }, [sport]);


  useEffect(() => {
    const fetchLineups = async () => {
      try {
        const response = await fetch(`/lineups?sport=${sport}`);
        if (response.ok) {
          const data = await response.json();
          console.log('nfl lineup', data)
          setLineupsData(data);
          setFilteredLineups(data);
          setIsLineupsAvailable(data.length > 0);
        } else {
          console.error(`Error fetching lineups data: ${response.statusText}`);
          setIsLineupsAvailable(false);
        }
      } catch (error) {
        console.error(`Error fetching lineups data:`, error);
        setLineupsData([]);
        setFilteredLineups([]);
        setIsLineupsAvailable(false);
      }
    };

    fetchLineups();
  }, [sport]);

  useEffect(() => {
    const filterByTeamOpponent = (lineup) => {
      if (!filters.teamOpponent || filters.teamOpponent.length === 0) {
        return true; // If no teams are selected, don't filter out anything
      }

      const matchup = `${lineup["Visiting Team"]} vs ${lineup["Home Team"]}`.toLowerCase();
      
      return filters.teamOpponent.some(team =>
        matchup.includes(team.toLowerCase())
      );
    };

    const filterUpcomingGames = (lineup) => {
      const currentTime = new Date();
      const gameTimeStr = `${new Date().toLocaleDateString()} ${lineup[
        "Game Time"
      ].replace(" ET", "")}`;
      const gameTime = new Date(gameTimeStr);

      return gameTime > currentTime;
    };

    const filtered = lineupsData.filter(filterByTeamOpponent).filter(filterUpcomingGames);

    setFilteredLineups(filtered);
  }, [filters.teamOpponent, lineupsData]);

  const fetchStatsForPlayer = useCallback(
    async (playerName) => {
      if (fetchedPlayersRef.current.has(playerName.toLowerCase())) return;
  
      try {
        let url = '';
        if (sport === 'mlb') {
          url = `/player_stats?player_name=${encodeURIComponent(playerName)}`;
        } else if (sport === 'wnba') {
          url = '/wnba_stats'; // Adjust the URL to fetch WNBA stats
        } else if (sport === 'nfl') {  // Handle NFL players
          url = `/nfl_stats?player_name=${encodeURIComponent(playerName)}`;  // Example endpoint
        }
  
        const response = await fetch(url);
  
        if (response.ok) {
          const data = await response.json();
          if (sport === 'mlb') {
            setMlbStatsData((prev) => [...prev, { playerName, data }]);
          } else if (sport === 'wnba') {
            setWnbaStatsData((prev) => [...prev, data]); // Assume WNBA stats are an array
          } else if (sport === 'nfl') {
            setNflStatsData((prev) => [...prev, { playerName, data }]);  // Store NFL stats
          }
          fetchedPlayersRef.current.add(playerName.toLowerCase());
        } else {
          console.error(`Error fetching stats for ${playerName}: ${response.statusText}`);
        }
      } catch (error) {
        console.error(`Error fetching stats for ${playerName}:`, error);
      }
    },
    [sport]
  );
  
  const showHistoricalData = async (playerName, prop, overUnder) => {
    try {
      const response = await fetch(
        `/get_historical_data?player_name=${encodeURIComponent(
          playerName
        )}&prop=${encodeURIComponent(prop)}&over_under=${encodeURIComponent(
          overUnder
        )}&sport=${sport}`
      );
      if (response.ok) {
        const data = await response.json();
        setHistoricalData(data);
        document.getElementById("historicalModal").style.display = "block";
      } else {
        throw new Error(`Error fetching historical data: ${response.statusText}`);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getPaginatedData = useCallback(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return safeFilteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [safeFilteredData, currentPage]);

  const totalPages = Math.ceil(safeFilteredData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
  };

  const handleRowExpansion = async (index, playerName) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(index)) {
        newExpandedRows.delete(index);
      } else {
        newExpandedRows.add(index);
        fetchStatsForPlayer(playerName);
      }
      return newExpandedRows;
    });
  };

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);  // Reset to the first page when filters change
    }
  }, [filters]);
  return (
    <div className="odds-page-container">
      <Navbar
        lastUpdated={lastUpdated}
        sport={sport}
        setSport={setSport}
        toggleSidebar={toggleSidebar}
      />
      <div className={`main-content ${sidebarOpen ? "pl-64" : "pl-0"}`}>
        <div className="odds-page-columns">
          <div className="left-column">

            <StatTable
              oddsData={getPaginatedData()}
              mlbStatsData={mlbStatsData}
              wnbaStatsData={wnbaStatsData}
              nflStatsData={nflStatsData}  // <-- Pass NFL stats to StatTable
              wnbaTeamStatsData={wnbaTeamStatsData} // Pass WNBA stats to StatTable
              showHistoricalData={showHistoricalData}
              favorites={favorites}
              setFavorites={setFavorites}
              subscriptionType={subscriptionType}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              sortData={sortData}
              visibleSportsbooks={visibleSportsbooks}
              sport={sport}
              expandedRows={expandedRows}
              onRowExpansion={handleRowExpansion}
              fetchStatsForPlayer={fetchStatsForPlayer}
              lineupsData={lineupsData} // Pass lineupsData to StatTable
              fetchOdds={fetchOdds}
              underdogData={underdogData}
              setCurrentPage={setCurrentPage}

            />
          </div>
          <div className="lineups-display-container">
            {isLineupsAvailable ? (
              <LineupsDisplay lineupsData={filteredLineups} />
            ) : (
              <div className="no-lineups">No lineups available for this sport.</div>
            )}
          </div>
        </div>
      </div>

      <div id="historicalModal">
        <div className="modal-header">
          <select
            id="data-filter"
            onChange={(e) => setDataFilter(parseInt(e.target.value, 10))}
            value={dataFilter}
            className="select select-bordered"
          >
            <option value={10}>Last 10</option>
            <option value={50}>Last 50</option>
            <option value={historicalData.length}>All</option>
          </select>
          <button
            onClick={() =>
              (document.getElementById("historicalModal").style.display = "none")
            }
            className="red-button"
          >
            Close
          </button>
        </div>
        <HistoricalChart data={historicalData} dataFilter={dataFilter} sport={sport} />
      </div>
    </div>
  );
};

export default OddsPage;
