import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProfilePage.css';

const ProfilePage = ({ setAvatar }) => {
  const [user, setUser] = useState({
    email: 'user@example.com',
    subscriptionType: 'free',
    avatar: '/static/uploads/avatars/default-avatar.png',
  });
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    // Fetch user data from backend
    fetch('/get_user_profile', {
      credentials: 'include'
    }).then(response => response.json())
      .then(data => setUser(data));
  }, []);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif')) {
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setUser((prevUser) => ({ ...prevUser, avatar: reader.result }));
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Image must be one of 'png', 'jpg', 'jpeg' or 'gif'", { autoClose: 3000 });
    }
  };

  const handleAvatarUpload = async () => {
    if (!avatarFile) {
      toast.error("Please select an image to upload.", { autoClose: 3000 });
      return;
    }

    const formData = new FormData();
    formData.append('avatar', avatarFile);

    const response = await fetch('/upload_avatar', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setUser((prevUser) => ({ ...prevUser, avatar: data.avatar_url }));
      setAvatar(data.avatar_url); // Update avatar in Navbar
      toast.success("Avatar uploaded successfully!", { autoClose: 3000 });
    } else {
      toast.error("Failed to upload avatar", { autoClose: 3000 });
    }
  };

  return (
    <div className="profile-page-container">
      <ToastContainer />
      <h1 className="profile-page-title">Profile Page</h1>
      <div className="profile-card">
        <div className="profile-avatar">
          <img
            src={user.avatar}
            alt="User Avatar"
            className="avatar-img"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            className="file-input"
          />
          <button onClick={handleAvatarUpload} className="btn btn-primary">
            Change Avatar
          </button>
        </div>
        <div className="profile-info">
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Subscription:</strong> {user.subscriptionType}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
