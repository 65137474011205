import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import './SubscribePage.css'; // Import the CSS file for SubscribePage

const SubscribePage = () => {
  const [subscriptionType, setSubscriptionType] = useState('free');
  const navigate = useNavigate();

  const fetchSubscriptionType = async () => {
    try {
      const response = await fetch('/get_user_profile', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.subscriptionType) {
        setSubscriptionType(data.subscriptionType);
      } else {
        console.error('Error fetching subscription type:', data.message);
      }
    } catch (error) {
      console.error('Error fetching subscription type:', error);
    }
  };

  useEffect(() => {
    fetchSubscriptionType();
  }, []);

  const handleSubscribe = async (type) => {
    try {
      const response = await fetch('/update_subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ subscriptionType: type }),
      });

      if (response.ok) {
        setSubscriptionType(type);
        toast.success('Subscription updated! ');
        setTimeout(() => {}, 2000);
        window.location.reload(); // Reload the page

      } else {
        console.error('Subscription failed');
        toast.error('Subscription update failed');
      }
    } catch (error) {
      console.error('Error during subscription:', error);
      toast.error('Error during subscription');
    }
  };

  return (
    <div className="subscribe-page min-h-screen flex items-center justify-center bg-gray-800 p-8">
      <div className="card-container flex flex-col md:flex-row justify-center items-stretch w-full space-y-8 md:space-y-0 md:space-x-8">
        <div className="card w-full md:w-1/2 bg-base-100 shadow-xl flex flex-col">
          <div className="card-body flex flex-col items-center justify-between h-full">
            <div className="text-center">
              <h2 className="card-title text-2xl text-white">Free</h2>
              <p className="text-4xl font-bold my-4 text-white">$0<span className="text-lg">/mo</span></p>
            </div>
            <div className="info-lines flex-grow w-full text-left text-white">
              <div className="info-line"><FaCheckCircle /> Basic Real-Time Odds</div>
              <div className="info-line"><FaCheckCircle /> Limited Favorites (up to 5)</div>
              <div className="info-line"><FaCheckCircle /> Basic Filters</div>
            </div>
            <div className="card-actions justify-center mt-4 w-full">
              <button
                className={`btn w-full ${subscriptionType === 'free' ? 'btn-disabled' : 'btn-primary'}`}
                onClick={() => handleSubscribe('free')}
                disabled={subscriptionType === 'free'}
              >
                {subscriptionType === 'free' ? 'Current' : 'Choose Free'}
              </button>
            </div>
          </div>
        </div>
        <div className="card w-full md:w-1/2 bg-primary text-primary-content shadow-xl flex flex-col relative">
          <div className="card-body flex flex-col items-center justify-between h-full">
            <div className="absolute top-0 left-0 bg-green-500 text-white px-2 py-1 transform -rotate-12 origin-top-left">Recommended</div>
            <div className="text-center">
              <h2 className="card-title text-2xl text-white">Premium</h2>
              <p className="text-4xl font-bold my-4 text-white">$15<span className="text-lg">/mo</span></p>
            </div>
            <div className="info-lines flex-grow w-full text-left text-white">
              <div className="info-line"><FaCheckCircle /> All Real-Time Odds</div>
              <div className="info-line"><FaCheckCircle /> Advanced Player Statistics</div>
              <div className="info-line"><FaCheckCircle /> Unlimited Favorites</div>
              <div className="info-line"><FaCheckCircle /> Advanced Filters & Sorting</div>
              <div className="info-line"><FaCheckCircle /> Full Historical Data (last 30 days)</div>
              <div className="info-line"><FaCheckCircle /> Notifications & Alerts</div>
              <div className="info-line"><FaCheckCircle /> View Live Prop Availability PrizePicks & Underdog</div>
            </div>
            <div className="card-actions justify-center mt-4 w-full">
              <button
                className={`btn w-full ${subscriptionType === 'premium' ? 'btn-disabled' : 'btn-secondary'}`}
                onClick={() => handleSubscribe('premium')}
                disabled={subscriptionType === 'premium'}
              >
                {subscriptionType === 'premium' ? 'Current' : 'Choose Premium'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePage;
