import './LineupsDisplay.css';
import React, { useEffect, useState } from 'react';

const LineupsDisplay = ({ lineupsData }) => {
    const [filteredLineups, setFilteredLineups] = useState(lineupsData);

    // Function to check and update the displayed lineups
    const updateLineups = () => {
        const currentTime = new Date();
        console.log("Current Time:", currentTime);

        const filtered = lineupsData.filter(lineup => {
            const gameTimeStr = `${new Date().toLocaleDateString()} ${lineup["Game Time"].replace(' ET', '')}`;
            const gameTime = new Date(gameTimeStr);
            console.log("Parsed Game Time:", gameTime, "for Game Time String:", lineup["Game Time"]);

            if (isNaN(gameTime.getTime())) {
                console.error(`Invalid game time: ${gameTimeStr}`);
                return true;  // Keep the lineup if the time parsing fails
            }

            const isUpcoming = gameTime > currentTime;
            console.log(`Game time ${lineup["Game Time"]} is upcoming:`, isUpcoming);

            return isUpcoming;  // Keep lineups where the game time is in the future
        });

        setFilteredLineups(filtered);
        console.log("Filtered Lineups:", filtered);
    };

    useEffect(() => {
        // Check every minute if any lineups need to be removed
        const intervalId = setInterval(updateLineups, 60000);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [lineupsData]);

    useEffect(() => {
        updateLineups();
    }, []);

    return (
        <div className="lineups-grid">
            {lineupsData.length > 0 ? (
                lineupsData.map((lineup, index) => (
                    <div key={index} className="lineup-card">
                        <div className="lineup-header">
                            <div className="game-info">
                                <h4>{lineup["Game Time"]}</h4>
                            </div>
                            <div className="teams">
                                <span className="team-name">{lineup["Visiting Team"]}</span>
                                <span className="vs">vs</span>
                                <span className="team-name">{lineup["Home Team"]}</span>
                            </div>
                        </div>
                        <div className="team-container">
                            <div className="team">
                                <h5>Visiting Team</h5>
                                <ul>
                                    {lineup['Visiting Players'].split(' | ').map((player, idx) => (
                                        <li key={idx}>{player}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="team">
                                <h5>Home Team</h5>
                                <ul>
                                    {lineup['Home Players'].split(' | ').map((player, idx) => (
                                        <li key={idx}>{player}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="lineup-footer">
                            <p>Line: {lineup.Line}</p>
                            <p>O/U: {lineup['Over/Under Runs']}</p>
                        </div>
                    </div>
                ))
            ) : (
                <div className="no-data-message">No lineups available</div>
            )}
        </div>
    );
};

export default LineupsDisplay;
