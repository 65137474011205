import React from 'react';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa'; // Import sort icons
import './TableHeader.css'; // Link to the CSS file

const TableHeader = ({ sortConfig, handleSortClick, visibleSportsbooks, filters = {} }) => {
  const headers = [
    { label: 'Player', key: 'PlayerName', sortable: false },
    { label: 'Matchup', key: 'teamVsOpponent', sortable: false },
    { label: 'Prop', key: 'Prop', sortable: false },
    { label: 'Over/Under', key: 'Over_Under', sortable: false },
    ...visibleSportsbooks.map((book) => ({
      label: book,
      key: book.toLowerCase(),
      sortable: true,
    })),
    { label: "Implied Probability", key: 'impliedProb', sortable: true },
    { label: 'Underdog', key: 'underdog', sortable: false },
    { label: 'Actions', key: 'actions', sortable: false },
  ];

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'Asc') {
        return <FaSortUp className="sort-icon" />;
      } else if (sortConfig.direction === 'Desc') {
        return <FaSortDown className="sort-icon" />;
      }
    }
    return <FaSort className="sort-icon" />;
  };

  return (
    <thead>
      <tr>
        {headers.map(({ label, key, sortable }) => (
          <th
            key={key}
            className={sortable ? `sortable ${sortConfig.key === key ? sortConfig.direction?.toLowerCase() : ''}` : ''}
            onClick={sortable ? () => handleSortClick(key) : undefined}
          >
            <div className="header-content">
              <span className="header-label">
                {label.split('\n').map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
              </span>
              {sortable && getSortIcon(key)}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};


export default TableHeader;
