import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { FaUserCircle } from 'react-icons/fa';

const Navbar = ({ lastUpdated, sport, setSport, handleLogout, avatar, subscriptionType }) => {
  const [isSportsDropdownOpen, setIsSportsDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleSportChange = (newSport) => {
    setSport(newSport);
    navigate(`/${newSport}`);
    setIsSportsDropdownOpen(false);
  };

  const handleSubscribe = () => {
    navigate('/subscribe');
  };

  const toggleDropdown = (dropdown) => {
    if (dropdown === 'sports') {
      setIsSportsDropdownOpen(!isSportsDropdownOpen);
      setIsProfileDropdownOpen(false);
    } else if (dropdown === 'profile') {
      setIsProfileDropdownOpen(!isProfileDropdownOpen);
      setIsSportsDropdownOpen(false);
    }
  };

  return (
    <div className="navbar-container">
      {lastUpdated && (
        <div className="navbar bg-base-100 shadow-lg px-4 py-2 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <a className="btn btn-ghost normal-case text-xl" href="/login">
              <img src="/images/logo.png" alt="Prop Scout" className="logo" />
            </a>
            <div className="text-sm text-gray-400 last-updated">
              Last updated: {lastUpdated}
            </div>
          </div>
          <div className="flex items-center space-x-4 navbar-right">
            <div className="dropdown">
              <button
                className="btn btn-ghost dropdown-toggle"
                onClick={() => toggleDropdown('sports')}
              >
                Sports
              </button>
              {isSportsDropdownOpen && (
                <div className="dropdown-menu show">
                  <button
                    className={`dropdown-item ${sport === 'mlb' ? 'active' : ''}`}
                    onClick={() => handleSportChange('mlb')}
                  >
                    MLB
                  </button>
                  <button
                    className={`dropdown-item ${sport === 'wnba' ? 'active' : ''}`}
                    onClick={() => handleSportChange('wnba')}
                  >
                    WNBA
                  </button>
                  <button
                    className={`dropdown-item ${sport === 'nfl' ? 'active' : ''}`}
                    onClick={() => handleSportChange('nfl')}
                  >
                    NFL
                  </button>
                  <button
                    className={`dropdown-item ${sport === 'nba' ? 'active' : ''}`}
                    onClick={() => handleSportChange('nba')}
                  >
                    NBA
                  </button>
                </div>
              )}
            </div>
            <a
              className="btn btn-ghost normal-case text-xl"
              href="/top-props" // Add this link to navigate to the Top Props page
            >
              Top Props
            </a>
            <div className="dropdown">
              <button
                className="btn btn-ghost dropdown-toggle"
                onClick={() => toggleDropdown('profile')}
              >
                <img src={avatar} alt="User Avatar" className="avatar" />
              </button>
              {isProfileDropdownOpen && (
                <div className="dropdown-menu show dropdown-menu-right profile-dropdown">
                  <button className="dropdown-item" onClick={() => navigate('/profile')}>Profile</button>
                  <button className="dropdown-item" onClick={handleSubscribe}>Subscriptions</button>
                  <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="navbar-border"></div>
    </div>
  );
};

export default Navbar;
