import React, { useState, useEffect } from 'react';
import './TopProps.css'; // Style this file to match your app's design

const TopProps = () => {
  const [topProps, setTopProps] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopProps = async () => {
      try {
        const response = await fetch('/get_top_props');
        if (response.ok) {
          const data = await response.json();
          setTopProps(data.top_props);
        } else {
          console.error(`Error fetching top props: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error fetching top props:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopProps();
  }, []);

  const renderTopPropsForSport = (sport) => {
    const sportProps = topProps.filter(prop => prop.sport === sport);
    if (sportProps.length === 0) {
      return <div>No top props available for {sport.toUpperCase()}</div>;
    }
    return sportProps.map((prop, index) => (
      <div key={index} className="top-prop-item">
        <strong>{prop.player_name} - {prop.prop_type}</strong>: {prop.analysis}
        <br />
        <em>Confidence Score: {prop.confidence_score}</em>
      </div>
    ));
  };

  return (
    <div className="top-props-page">
      <h1>Top Props</h1>
      {loading ? (
        <div className="loading-message">Loading Top Props...</div>
      ) : (
        <>
          <section>
            <h2>MLB</h2>
            {renderTopPropsForSport('mlb')}
          </section>
          <section>
            <h2>NBA</h2>
            {renderTopPropsForSport('nba')}
          </section>
          <section>
            <h2>WNBA</h2>
            {renderTopPropsForSport('wnba')}
          </section>
          <section>
            <h2>NFL</h2>
            {renderTopPropsForSport('nfl')}
          </section>
        </>
      )}
    </div>
  );
};

export default TopProps;
