import React from 'react';
import ExpandedRow from './ExpandedRow';
import { FaHeart, FaRegHeart, FaSpinner, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const TableRow = ({
  odds,
  sport,
  isFavorite,
  toggleFavorite,
  showHistoricalData,
  subscriptionType,
  visibleSportsbooks,
  toggleRowExpansion,
  isRowExpanded,
  playerStats,
  loading, 
  underdogData,
  lineupsData,
  wnbaTeamStatsData // New prop for WNBA team stats
}) => {
  const toggleFavoriteHandler = (e) => {
    e.stopPropagation(); // Prevent event propagation to avoid expanding the row when clicking the favorite icon
    toggleFavorite(sport, odds.PlayerName, odds.Prop, odds.Over_Under, isFavorite);
  };

  const showHistoricalDataHandler = (e) => {
    e.stopPropagation(); // Prevent event propagation to avoid expanding the row when clicking the historical data button
    showHistoricalData(odds.PlayerName, odds.Prop, odds.Over_Under);
  };

  const expandRowHandler = (e) => {
    e.stopPropagation(); // Prevent the row from expanding if clicking inside the row but not on the icon
    toggleRowExpansion();
  };

  const teammatesOut = [];
  if (lineupsData) {
    lineupsData.forEach((lineup) => {
      if (lineup['Visiting Team'] === odds.team) {
        lineup['Visiting Players'].split(' | ').forEach((player) => {
          if (player.includes('(OUT)')) {
            teammatesOut.push(player.replace(' (OUT)', ''));
          }
        });
      }
      if (lineup['Home Team'] === odds.team) {
        lineup['Home Players'].split(' | ').forEach((player) => {
          if (player.includes('(OUT)')) {
            teammatesOut.push(player.replace(' (OUT)', ''));
          }
        });
      }
    });
  }

  const normalizeName = name => name.toLowerCase().trim();

  // Normalize the full player name and prop type
  const normalizedPlayerName = normalizeName(odds.PlayerName);
  const propType = normalizeName(odds.Prop);

  // Find the matching entry in the underdog data
  const underdogLine = underdogData.find(entry =>
    normalizeName(entry['Player Name']) === normalizedPlayerName && normalizeName(entry['Stat Type']) === propType
  );

  // Determine the stat value and multiplier
  const statValue = underdogLine ? underdogLine['Stat Value'] : null;
  const underdogMultiplier = statValue 
    ? (odds.Over_Under === 'Over' ? underdogLine['Higher Option'] : underdogLine['Lower Option']) 
    : null;

  // If no stat value is found, set display to 'None'
  const displayValue = statValue && underdogMultiplier 
    ? `${statValue} (Multiplier: ${underdogMultiplier})`
    : 'None';

  return (
    <>
      <tr className="expandable-row">
        <td data-label="Player">
          <div className="player-info">
            <span
              className={`favorite-icon ${isFavorite ? 'active' : ''}`}
              onClick={toggleFavoriteHandler}
              aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
            >
              {isFavorite ? <FaHeart className="text-red-500" /> : <FaRegHeart className="text-gray-400" />}
            </span>
            <img
              src={odds.ImageURL}
              alt={odds.PlayerName}
              className="object-cover rounded-full"
            />
            <span className="player-name">{odds.PlayerName}</span>
            {loading && <FaSpinner className="loading-spinner animate-spin ml-2" />} 
            <span className="expand-icon" onClick={expandRowHandler}>
              {isRowExpanded ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
        </td>
        <td data-label="Matchup">{odds.opp?.includes('@') ? `${odds.team} @ ${odds.opp.replace('@', '')}` : `${odds.team} vs ${odds.opp}`}</td>
        <td data-label="Prop">{odds.Prop}</td>
        <td data-label="Over/Under">{odds.Over_Under}</td>
        <td data-label="DraftKings">{odds.draftkings}</td>
        <td data-label="FanDuel">{odds.fanduel}</td>
        <td data-label="MGM">{odds.mgm}</td>
        <td data-label="BetRivers">{odds.betrivers}</td>
        {sport === 'mlb' && <td data-label="Pinnacle">{odds.pinnacle}</td>}
        <td data-label="Implied Probability">{subscriptionType === 'free' ? 'Locked' : `${parseFloat(odds.Implied_Prob).toFixed(2)}%`}</td>
        <td data-label="Underdog Line">
          {displayValue}
        </td>
        <td data-label="Actions">
          <button
            className="view-historical btn btn-secondary"
            onClick={showHistoricalDataHandler}
            aria-label={`View historical odds for ${odds.PlayerName}`}
          >
            Historical Odds
          </button>
        </td>
      </tr>
      {isRowExpanded && (
        <tr>
          <td colSpan="12">
            <ExpandedRow
              odds={odds}
              playerStats={playerStats}
              sport={sport}
              lineupsData={lineupsData}  // Pass lineups data here\
              teammatesOut={teammatesOut}
              teamStats={sport === 'wnba' ? wnbaTeamStatsData : null} // Pass teamStats only if sport is WNBA
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
