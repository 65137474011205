import React, { useState, useEffect, useMemo } from 'react';
import './ExpandedRow.css';

const MLBExpandedRow = ({ odds, playerStats }) => {
  const [loading, setLoading] = useState(true);
  const [timeoutReached, setTimeoutReached] = useState(false);
  
  const pitcherVsBattersData = playerStats?.data?.pitcher_vs_batters || [];
  const batterVsPitcherData = playerStats?.data?.player_vs_pitcher?.[0] || {};
  const basicBattingStatsData = playerStats?.data?.basic_batting_stats?.[0] || {};
  const basicPitchingStatsData = playerStats?.data?.basic_pitching_stats?.[0] || {};

  const hasMatchupData = batterVsPitcherData?.pitcher != null && batterVsPitcherData?.ab > 0;
  const totalBatters = 9;
  const knownBatters = pitcherVsBattersData.length;
  const newBatters = totalBatters - knownBatters;

  const hasData = playerStats && Object.keys(playerStats?.data || {}).length > 0;

  const getPropLine = () => {
    return odds.fanduel|| odds.pinnacle|| odds.draftkings || odds.mgm || odds.betrivers;
  };

  const propLine = parseFloat(getPropLine()?.split(' ')[0]?.replace(/[()]/g, '') || 0);

  const calculateAggregateStats = () => {
    const kPct = parseFloat(basicPitchingStatsData.kpct || 0);
    const bbPct = parseFloat(basicPitchingStatsData.bbpct || 0);
    const isoPower = parseFloat(basicBattingStatsData.isopower || 0);
    const slgPctVsHand = parseFloat(batterVsPitcherData.slg || 0);
    const wOBA = parseFloat(basicBattingStatsData.woba || 0);
    const flyBallPct = parseFloat(basicBattingStatsData.fbpct || 0);
    const hrPerFbPct = parseFloat(basicBattingStatsData.hrfbpct || 0);
    const babip = parseFloat(basicBattingStatsData.babip || 0);

    const aggregateKRateVsBatters = pitcherVsBattersData.reduce(
      (sum, batter) => sum + parseFloat(batter.so) / (parseInt(batter.ab) || 1),
      0
    ) / (pitcherVsBattersData.length || 1);

    const recentPerformance = parseFloat(basicPitchingStatsData.swstrikepct || basicBattingStatsData.contactrate || 0);  // Example of using recent form, adjusted for pitchers
    console.log(basicBattingStatsData)
    console.log(playerStats)

    return {
      kPct,
      bbPct,
      isoPower,
      slgPctVsHand,
      wOBA,
      flyBallPct,
      hrPerFbPct,
      babip,
      aggregateKRateVsBatters,
      recentPerformance,
    };
  };

  const aggregateStats = calculateAggregateStats();

  useEffect(() => {
    if (playerStats && Object.keys(playerStats).length > 0) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setTimeoutReached(true);
        setLoading(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [playerStats]);

  const getColor = (stat, type, context) => {
    switch (type) {
      case 'K%':
        return context === 'Over'
          ? stat > 25
            ? 'favorable'
            : stat > 20
            ? 'neutral'
            : 'unfavorable'
          : stat < 20
          ? 'favorable'
          : stat < 25
          ? 'neutral'
          : 'unfavorable';
      case 'BB%':
        return context === 'Over'
          ? stat < 6
            ? 'favorable'
            : stat < 8
            ? 'neutral'
            : 'unfavorable'
          : stat > 8
          ? 'favorable'
          : stat > 6
          ? 'neutral'
          : 'unfavorable';
      case 'ISO Power':
      case 'SLG% vs Pitcher':
      case 'Fly Ball%':
      case 'HR/FB%':
      case 'wOBA':
      case 'Recent Form':
        return context === 'Over'
          ? stat > 0.300
            ? 'favorable'
            : stat > 0.200
            ? 'neutral'
            : 'unfavorable'
          : stat < 0.200
          ? 'favorable'
          : stat < 0.300
          ? 'neutral'
          : 'unfavorable';
      case 'Pitcher K% vs Batter':
        return context === 'Over'
          ? stat > 25
            ? 'favorable'
            : stat > 20
            ? 'neutral'
            : 'unfavorable'
          : stat < 20
          ? 'favorable'
          : stat < 25
          ? 'neutral'
          : 'unfavorable';
      default:
        return 'neutral';
    }
  };

  const evaluateConfidenceLevel = useMemo(() => {
    if (loading) return { over: 'Loading...', under: 'Loading...' };
  
    let overScore = 0;
    let underScore = 0;
  
    // Example of trend-based metrics (these should be calculated based on recent games)
    const recentTrendKPct = aggregateStats.recentPerformance; // Assume recentPerformance is derived from the data as shown earlier
    const opponentStrikeoutRate = pitcherVsBattersData.reduce(
      (sum, batter) => sum + parseFloat(batter.so) / (parseInt(batter.ab) || 1),
      0
    ) / (pitcherVsBattersData.length || 1) * 100; // Assuming opponent data can be derived from pitcherVsBattersData
  
    const opponentSluggingRate = batterVsPitcherData.slg || 0; // Assuming opponentSluggingRate can be derived from batterVsPitcherData.slg
    
    if (odds.Prop === 'Strikeouts') {
      // Evaluation logic for pitchers
      overScore += aggregateStats.kPct > 20 ? 2 : 0;
      overScore += aggregateStats.aggregateKRateVsBatters > 0.25 ? 2 : 0;
      underScore += aggregateStats.kPct <= 20 ? 2 : 0;
  
      // Introduce trend analysis
      overScore += recentTrendKPct > aggregateStats.kPct ? 1 : 0;
      underScore += recentTrendKPct < aggregateStats.kPct ? 1 : 0;
  
      // Consider opponent adjustment
      if (opponentStrikeoutRate > 20) {
        overScore += 1;
      } else {
        underScore += 1;
      }
  
      if (aggregateStats.kPct > propLine) {
        overScore += 1;
      } else {
        underScore += 1;
      }
  
      if (newBatters > 3) {
        overScore -= 1;
        underScore += 1;
      }
  
      if (!hasMatchupData) {
        overScore -= 1;
        underScore += 1;
      }
  
    } else if (odds.Prop === 'Total Bases') {
      // Evaluation logic for batters
      overScore += aggregateStats.isoPower > 0.200 ? 2 : 0;
      overScore += aggregateStats.slgPctVsHand > 0.400 ? 2 : 0;
      overScore += aggregateStats.flyBallPct > 35 ? 2 : 0;
      underScore += aggregateStats.isoPower <= 0.200 ? 2 : 0;
      underScore += aggregateStats.flyBallPct <= 35 ? 2 : 0;
  
      // Trend analysis
      overScore += recentTrendKPct > aggregateStats.isoPower ? 1 : 0;
      underScore += recentTrendKPct < aggregateStats.isoPower ? 1 : 0;
  
      // Opponent adjustment
      if (opponentSluggingRate < 0.400) {
        underScore += 1;
      } else {
        overScore += 1;
      }
  
      if (aggregateStats.isoPower > propLine) {
        overScore += 1;
      } else {
        underScore += 1;
      }
  
      if (newBatters > 3) {
        overScore -= 1;
        underScore += 1;
      }
  
      if (!hasMatchupData) {
        overScore -= 1;
        underScore += 1;
      }
  
    } else if (odds.Prop === 'Runs') {
      // Evaluation logic for batters
      overScore += aggregateStats.wOBA > 0.320 ? 2 : 0;
      overScore += aggregateStats.babip > 0.300 ? 2 : 0;
      underScore += aggregateStats.wOBA <= 0.320 ? 2 : 0;
  
      // Trend analysis
      overScore += recentTrendKPct > aggregateStats.wOBA ? 1 : 0;
      underScore += recentTrendKPct < aggregateStats.wOBA ? 1 : 0;
  
      // Opponent adjustment
      if (opponentSluggingRate < 0.400) {
        underScore += 1;
      } else {
        overScore += 1;
      }
  
      if (aggregateStats.wOBA > propLine) {
        overScore += 1;
      } else {
        underScore += 1;
      }
  
      if (newBatters > 3) {
        overScore -= 1;
        underScore += 1;
      }
  
      if (!hasMatchupData) {
        overScore -= 1;
        underScore += 1;
      }
    }
  
    // Determine confidence level
    const overConfidence =
      overScore >= 7 ? 'Very High' : overScore >= 5 ? 'High' : overScore >= 3 ? 'Medium' : overScore >= 1 ? 'Low' : 'Very Low';
    const underConfidence =
      underScore >= 7 ? 'Very High' : underScore >= 5 ? 'High' : underScore >= 3 ? 'Medium' : underScore >= 1 ? 'Low' : 'Very Low';
  
    return {
      over: `${overConfidence}`,
      under: `${underConfidence}`,
    };
  }, [aggregateStats, odds, propLine, loading, hasMatchupData, newBatters, aggregateStats.recentPerformance, pitcherVsBattersData, batterVsPitcherData]);
  

  const confidenceLevel =
    odds.Over_Under === 'Over'
      ? evaluateConfidenceLevel.over
      : evaluateConfidenceLevel.under;
  const confidenceColor = confidenceLevel.includes('High')
    ? 'favorable'
    : confidenceLevel.includes('Medium')
    ? 'neutral'
    : 'unfavorable';

  useEffect(() => {
    if (hasData) {
      setLoading(false);
    }
  }, [hasData]);

  return (
    <div className="expanded-details">
      {loading ? (
        <div className="loading-message">Loading...</div>
      ) : timeoutReached ? (
        <div className="no-stats-message">No Stats Available</div>
      ) : (
        <>
          <div className={`confidence-indicator ${confidenceColor}`}>
            <strong>Confidence Level: {confidenceLevel}</strong>
          </div>
  
          <div className="stats-section">
            <div className="summary-grid">
              {/* Total Bases Stats */}
              {odds.Prop === 'Total Bases' && (
                <>
                  <h4 className="section-header">Total Bases Stats</h4>
                  <div className={`stats-grid ${getColor(aggregateStats.isoPower, 'ISO Power', odds.Over_Under)}`}>
                    <strong>ISO Power:</strong> {(aggregateStats.isoPower || 0).toFixed(3)}
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.slgPctVsHand, 'SLG% vs Pitcher', odds.Over_Under)}`}>
                    <strong>SLG% vs Pitcher:</strong> {(aggregateStats.slgPctVsHand || 0).toFixed(3)}
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.flyBallPct, 'Fly Ball%', odds.Over_Under)}`}>
                    <strong>Fly Ball%:</strong> {(aggregateStats.flyBallPct || 0).toFixed(1)}%
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.hrPerFbPct, 'HR/FB%', odds.Over_Under)}`}>
                    <strong>HR/FB%:</strong> {(aggregateStats.hrPerFbPct || 0).toFixed(1)}%
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.recentPerformance, 'Recent Form', odds.Over_Under)}`}>
                    <strong>Recent Form:</strong> {(aggregateStats.recentPerformance || 0).toFixed(3)}
                  </div>
                </>
              )}
  
              {/* Strikeouts Stats */}
              {odds.Prop === 'Strikeouts' && (
                <>
                  <h4 className="section-header">Strikeout Stats</h4>
                  <div className={`stats-grid ${getColor(aggregateStats.kPct, 'K%', odds.Over_Under)}`}>
                    <strong>K%:</strong> {(aggregateStats.kPct || 0).toFixed(1)}%
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.aggregateKRateVsBatters, 'Pitcher K% vs Batter', odds.Over_Under)}`}>
                    <strong>Pitcher K% vs Batter:</strong> {((aggregateStats.aggregateKRateVsBatters || 0) * 100).toFixed(1)}%
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.bbPct, 'BB%', odds.Over_Under)}`}>
                    <strong>BB%:</strong> {(aggregateStats.bbPct || 0).toFixed(1)}%
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.recentPerformance, 'Recent Form', odds.Over_Under)}`}>
                    <strong>Recent Form:</strong> {(aggregateStats.recentPerformance || 0).toFixed(3)}
                  </div>
                </>
              )}
  
              {/* Runs Stats */}
              {odds.Prop === 'Runs' && (
                <>
                  <h4 className="section-header">Runs Stats</h4>
                  <div className={`stats-grid ${getColor(aggregateStats.wOBA, 'wOBA', odds.Over_Under)}`}>
                    <strong>wOBA:</strong> {(aggregateStats.wOBA || 0).toFixed(3)}
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.babip, 'BABIP', odds.Over_Under)}`}>
                    <strong>BABIP:</strong> {(aggregateStats.babip || 0).toFixed(3)}
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.slgPctVsHand, 'SLG% vs Pitcher', odds.Over_Under)}`}>
                    <strong>SLG% vs Pitcher:</strong> {(aggregateStats.slgPctVsHand || 0).toFixed(3)}
                  </div>
                  <div className={`stats-grid ${getColor(aggregateStats.recentPerformance, 'Recent Form', odds.Over_Under)}`}>
                    <strong>Recent Form:</strong> {(aggregateStats.recentPerformance || 0).toFixed(3)}
                  </div>
                </>
              )}
            </div>
  
            {/* Only show the Batter vs. Pitcher table if it's not a Strikeouts prop */}
            {odds.Prop !== 'Strikeouts' && hasMatchupData && (
              <div className="batters-table-section">
                <div className="batters-table-wrapper">
                  <h4>Batter vs. {batterVsPitcherData.pitcher}</h4>
                  <table className="batters-table">
                    <thead>
                      <tr>
                        <th>AB</th>
                        <th>SO</th>
                        <th>AVG</th>
                        <th>OBP</th>
                        <th>SLG</th>
                        <th>2B</th>
                        <th>3B</th>
                        <th>BB</th>
                        <th>Hits</th>
                        <th>HR</th>
                        <th>RBI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{batterVsPitcherData.ab}</td>
                        <td>{batterVsPitcherData.so}</td>
                        <td>{batterVsPitcherData.avg}</td>
                        <td>{batterVsPitcherData.obp}</td>
                        <td>{batterVsPitcherData.slg}</td>
                        <td>{batterVsPitcherData.b2}</td>
                        <td>{batterVsPitcherData.b3}</td>
                        <td>{batterVsPitcherData.bb}</td>
                        <td>{batterVsPitcherData.h}</td>
                        <td>{batterVsPitcherData.hr}</td>
                        <td>{batterVsPitcherData.rbi}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
  
            {/* Show Pitcher vs. Batters table only for Strikeouts */}
            {odds.Prop === 'Strikeouts' && pitcherVsBattersData.length > 0 && (
              <div className="batters-table-section">
                <div className="batters-table-wrapper">
                  <h4>Pitcher vs. Batters</h4>
                  <table className="batters-table">
                    <thead>
                      <tr>
                        <th>Batter</th>
                        <th>AB</th>
                        <th>SO</th>
                        <th>AVG</th>
                        <th>Batting Hand</th>
                        <th>Hits</th>
                        <th>HR</th>
                        <th>OBP</th>
                        <th>RBI</th>
                        <th>SLG</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pitcherVsBattersData.map((batter, index) => (
                        <tr key={index}>
                          <td>{batter.player}</td>
                          <td>{batter.ab}</td>
                          <td>{batter.so}</td>
                          <td>{batter.avg}</td>
                          <td>{batter.bats}</td>
                          <td>{batter.h}</td>
                          <td>{batter.hr}</td>
                          <td>{batter.obp}</td>
                          <td>{batter.rbi}</td>
                          <td>{batter.slg}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};  

export default MLBExpandedRow;
