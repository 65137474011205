import React, { useMemo, useState, useEffect } from 'react';
import './NFLExpandedRow.css';

const NFLExpandedRow = ({ odds, playerStats, lineupsData }) => {
  console.log(lineupsData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (playerStats && playerStats.data) {
      setIsLoading(false); // Set loading to false once data is fetched
    }
  }, [playerStats]);

  const filteredPlayerStats = useMemo(() => {
    if (!playerStats || !playerStats.data || playerStats.data.length === 0) {
      return null;
    }

    const playerData = playerStats.data.filter(stat => stat.player_display_name === odds.PlayerName);
    if (!playerData || playerData.length === 0) {
      return null;
    }
    console.log('Filtered Player Data:', playerData);

    // Determine if the player has playoff games
    const hasPlayoffs = playerData.some(game => game.week >= 19 && game.week <= 23);

    const statsBySeason = playerData.reduce((acc, game) => {
      let seasonType = '';
      if (game.season === 2023) {
        seasonType = '2023 Regular Season';
      } else {
        // Adjust seasonType based on whether playoffs exist
        seasonType = '2024 Regular Season';
      }

      if (!acc[seasonType]) {
        acc[seasonType] = [];
      }

      acc[seasonType].push(game);
      return acc;
    }, {});
    console.log('Stats By Season:', statsBySeason);

    // Aggregate 2023 Regular Season Stats
    const regular2023Games = statsBySeason['2023 Regular Season'] || [];
    const aggregateStats2023 = regular2023Games.reduce((acc, game) => {
      acc.totalPassingYards += game.passing_yards || 0;
      acc.totalCompletions += game.completions || 0;
      acc.totalAttempts += game.attempts || 0;
      acc.totalRushYards += game.rushing_yards || 0;
      acc.totalReceivingYards += game.receiving_yards || 0;
      acc.totalReceptions += game.receptions || 0;
      acc.totalAirYardsShare += game.air_yards_share || 0;
      acc.totalTargetShare += game.target_share || 0;
      return acc;
    }, { totalPassingYards: 0, totalCompletions: 0, totalAttempts: 0, totalRushYards: 0, totalReceivingYards: 0, totalReceptions: 0, totalAirYardsShare: 0, totalTargetShare: 0 });

    const averageStats2023 = {
      avgPassingYards: regular2023Games.length ? (aggregateStats2023.totalPassingYards / regular2023Games.length).toFixed(1) : 0,
      avgCompletions: regular2023Games.length ? (aggregateStats2023.totalCompletions / regular2023Games.length).toFixed(1) : 0,
      avgAttempts: regular2023Games.length ? (aggregateStats2023.totalAttempts / regular2023Games.length).toFixed(1) : 0,
      avgRushYards: regular2023Games.length ? (aggregateStats2023.totalRushYards / regular2023Games.length).toFixed(1) : 0,
      avgReceivingYards: regular2023Games.length ? (aggregateStats2023.totalReceivingYards / regular2023Games.length).toFixed(1) : 0,
      avgReceptions: regular2023Games.length ? (aggregateStats2023.totalReceptions / regular2023Games.length).toFixed(1) : 0,
      avgAirYardsShare: regular2023Games.length ? (aggregateStats2023.totalAirYardsShare / regular2023Games.length).toFixed(2) : 0,
      avgTargetShare: regular2023Games.length ? (aggregateStats2023.totalTargetShare / regular2023Games.length).toFixed(2) : 0,
      avgTotalYards: regular2023Games.length ? ((aggregateStats2023.totalRushYards + aggregateStats2023.totalReceivingYards) / regular2023Games.length).toFixed(1) : 0
    };

    // Aggregate 2024 Regular Season Stats
    const regular2024Games = statsBySeason['2024 Regular Season'] || [];
    const lastFiveGames = regular2024Games.slice(0, 5);
    const aggregateStats2024 = lastFiveGames.reduce((acc, game) => {
      acc.totalPassingYards += game.passing_yards || 0;
      acc.totalCompletions += game.completions || 0;
      acc.totalAttempts += game.attempts || 0;
      acc.totalRushYards += game.rushing_yards || 0;
      acc.totalReceivingYards += game.receiving_yards || 0;
      acc.totalReceptions += game.receptions || 0;
      acc.totalAirYardsShare += game.air_yards_share || 0;
      acc.totalTargetShare += game.target_share || 0;
      return acc;
    }, { totalPassingYards: 0, totalCompletions: 0, totalAttempts: 0, totalRushYards: 0, totalReceivingYards: 0, totalReceptions: 0, totalAirYardsShare: 0, totalTargetShare: 0 });

    const averageStats2024 = {
      avgPassingYards: lastFiveGames.length ? (aggregateStats2024.totalPassingYards / lastFiveGames.length).toFixed(1) : 0,
      avgCompletions: lastFiveGames.length ? (aggregateStats2024.totalCompletions / lastFiveGames.length).toFixed(1) : 0,
      avgAttempts: lastFiveGames.length ? (aggregateStats2024.totalAttempts / lastFiveGames.length).toFixed(1) : 0,
      avgRushYards: lastFiveGames.length ? (aggregateStats2024.totalRushYards / lastFiveGames.length).toFixed(1) : 0,
      avgReceivingYards: lastFiveGames.length ? (aggregateStats2024.totalReceivingYards / lastFiveGames.length).toFixed(1) : 0,
      avgReceptions: lastFiveGames.length ? (aggregateStats2024.totalReceptions / lastFiveGames.length).toFixed(1) : 0,
      avgAirYardsShare: lastFiveGames.length ? (aggregateStats2024.totalAirYardsShare / lastFiveGames.length).toFixed(2) : 0,
      avgTargetShare: lastFiveGames.length ? (aggregateStats2024.totalTargetShare / lastFiveGames.length).toFixed(2) : 0,
      avgTotalYards: lastFiveGames.length ? ((aggregateStats2024.totalRushYards + aggregateStats2024.totalReceivingYards) / lastFiveGames.length).toFixed(1) : 0
    };

    // Aggregate Opponent Stats
    const opponentStats = playerData.filter(game => game.opponent_team === odds.opp);
    const aggregateOpponentStats = opponentStats.reduce((acc, game) => {
      acc.totalRushYards += game.rushing_yards || 0;
      acc.totalReceivingYards += game.receiving_yards || 0;
      acc.totalPassingYards += game.passing_yards || 0;
      acc.totalReceptions += game.receptions || 0;
      return acc;
    }, { totalRushYards: 0, totalReceivingYards: 0, totalPassingYards: 0, totalReceptions: 0 });

    const averageOpponentStats = {
      avgRushYards: opponentStats.length ? (aggregateOpponentStats.totalRushYards / opponentStats.length).toFixed(1) : 0,
      avgReceivingYards: opponentStats.length ? (aggregateOpponentStats.totalReceivingYards / opponentStats.length).toFixed(1) : 0,
      avgPassingYards: opponentStats.length ? (aggregateOpponentStats.totalPassingYards / opponentStats.length).toFixed(1) : 0,
      avgReceptions: opponentStats.length ? (aggregateOpponentStats.totalReceptions / opponentStats.length).toFixed(1) : 0,
      avgTotalYards: opponentStats.length ? ((aggregateOpponentStats.totalRushYards + aggregateOpponentStats.totalReceivingYards) / opponentStats.length).toFixed(1) : 0
    };

    return {
      statsBySeason,
      averageStats2023,
      averageStats2024,
      averageOpponentStats,
      hasPlayoffs
    };
  }, [playerStats, odds]);

  const getPropLine = () => {
    return odds.fanduel || odds.draftkings || odds.mgm || odds.betrivers;
  };

  const propLine = parseFloat(getPropLine()?.split(' ')[0]?.replace(/[()]/g, '') || 0);

  // Confidence Score Calculation
  const calculateConfidenceScore = (odds, playerStats, opponentStats) => {
    // Check if playerStats and opponentStats are valid and contain necessary data
    if (!playerStats || !opponentStats || !playerStats.averageStats2023 || !playerStats.averageStats2024) {
      return 50; // Default confidence if stats are not available
    }

    let confidence = 50; // Start at a baseline of 50%

    // Determine the number of games played in 2024 to set weights
    const gamesPlayed2024 = playerStats.statsBySeason?.['2024 Regular Season']?.length || 0;
    const maxGames = 17; // Assuming a typical NFL regular season length

    // Calculate weights
    const weight2024 = Math.min(1, gamesPlayed2024 / maxGames);
    const weight2023 = 1 - weight2024;

    // Safely compute weighted averages for various stats
    const weightedAvgPassingYards = (weight2024 * (playerStats.averageStats2024.avgPassingYards || 0)) +
                                    (weight2023 * (playerStats.averageStats2023.avgPassingYards || 0));
    const weightedAvgRushYards = (weight2024 * (playerStats.averageStats2024.avgRushYards || 0)) +
                                 (weight2023 * (playerStats.averageStats2023.avgRushYards || 0));
    const weightedAvgReceivingYards = (weight2024 * (playerStats.averageStats2024.avgReceivingYards || 0)) +
                                      (weight2023 * (playerStats.averageStats2023.avgReceivingYards || 0));
    const weightedAvgReceptions = (weight2024 * (playerStats.averageStats2024.avgReceptions || 0)) +
                                  (weight2023 * (playerStats.averageStats2023.avgReceptions || 0));

    // Calculate advanced metrics like air yards, target share, etc., safely
    const avgAirYardsShare = (weight2024 * (playerStats.averageStats2024.avgAirYardsShare || 0)) +
                             (weight2023 * (playerStats.averageStats2023.avgAirYardsShare || 0));
    const avgTargetShare = (weight2024 * (playerStats.averageStats2024.avgTargetShare || 0)) +
                           (weight2023 * (playerStats.averageStats2023.avgTargetShare || 0));

    // Compare weighted average stats to the prop line and adjust confidence
    switch (odds.Prop) {
      case 'Passing Yards':
        if (weightedAvgPassingYards > propLine) {
          confidence += 20; // Higher confidence if average passing yards exceed prop line
        } else {
          confidence -= 20; // Lower confidence if average passing yards are below prop line
        }
        // Opponent's passing defense impact
        if (opponentStats.avgPassingYards < propLine) {
          confidence += 10; // Strong opponent defense increases confidence in under
        } else {
          confidence -= 10; // Weak opponent defense decreases confidence in under
        }
        // Use passing_air_yards to gauge deep pass effectiveness
        if ((playerStats.averageStats2024.passing_air_yards || 0) > 200) { // Example threshold
          confidence += 5;
        }
        break;

      case 'Rushing Yards':
        if (weightedAvgRushYards > propLine) {
          confidence += 20;
        } else {
          confidence -= 20;
        }
        // Factor in opponent's rush defense
        if (opponentStats.avgRushYards < propLine) {
          confidence += 10;
        } else {
          confidence -= 10;
        }
        // Adjust for game script from lineup data (e.g., if the game is expected to be close)
        if (odds.Line > 7) { // Example: if the game line is a blowout
          confidence -= 5; // Less likely to rush if trailing
        }
        break;

      case 'Receiving Yards':
        if (weightedAvgReceivingYards > propLine) {
          confidence += 20;
        } else {
          confidence -= 20;
        }
        // Use target share and air yards share for more precise evaluation
        if (avgTargetShare > 0.20 && avgAirYardsShare > 0.30) { // Example thresholds
          confidence += 10;
        }
        // Consider opponent's pass defense against WRs
        if (opponentStats.avgReceivingYards < propLine) {
          confidence += 10;
        } else {
          confidence -= 10;
        }
        break;

      case 'Rush + Receiving Yards':
        const weightedAvgTotalYards = weightedAvgRushYards + weightedAvgReceivingYards;
        if (weightedAvgTotalYards > propLine) {
          confidence += 20;
        } else {
          confidence -= 20;
        }
        break;

      case 'Receptions':
        if (weightedAvgReceptions > propLine) {
          confidence += 20;
        } else {
          confidence -= 20;
        }
        // Use target share for more accuracy
        if (avgTargetShare > 0.25) {
          confidence += 10; // Player is a key part of the passing game
        }
        break;

      // Add more case logic for other prop types (e.g., touchdowns) with advanced metrics
      default:
        break;
    }

    // Recent performance boost (last 3 games)
    const recentPerformanceBoost = gamesPlayed2024 >= 3 ? 
      (playerStats.statsBySeason['2024 Regular Season'].slice(0, 3).reduce((acc, game) => acc + (game.passing_yards || 0), 0) / 3) : 0;
    if (recentPerformanceBoost > propLine) {
      confidence += 10;
    } else if (recentPerformanceBoost < propLine) {
      confidence -= 10;
    }

    // Clamp confidence to be within 0-100
    confidence = Math.max(0, Math.min(100, confidence));

    return confidence;
  };

  const confidenceScore = filteredPlayerStats ? calculateConfidenceScore(odds, filteredPlayerStats, filteredPlayerStats.averageOpponentStats) : 'N/A';

  const renderPropStats = (game) => {
    // Define reusable calculations
    const completionPercentage = game.attempts ? ((game.completions / game.attempts) * 100).toFixed(1) : 'N/A';
    const yardsPerAttempt = game.attempts ? (game.passing_yards / game.attempts).toFixed(1) : 'N/A';
    const airYardsShare = game.air_yards_share ? (game.air_yards_share * 100).toFixed(1) : 'N/A';
    const yardsPerCarry = game.carries ? (game.rushing_yards / game.carries).toFixed(1) : 'N/A';
    const totalYards = (game.rushing_yards + game.receiving_yards).toFixed(1);
    const totalTouches = game.carries + game.receptions;
    const yardsPerTouch = totalTouches ? (totalYards / totalTouches).toFixed(1) : 'N/A';
    const catchRate = game.targets ? ((game.receptions / game.targets) * 100).toFixed(1) : 'N/A';
    const yardsPerCatch = game.receptions ? (game.receiving_yards / game.receptions).toFixed(1) : 'N/A';
    const avgYardsPerReception = game.receptions ? (game.receiving_yards / game.receptions).toFixed(1) : 'N/A';

    switch (odds.Prop) {
      case 'Passing Yards':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Passing Yards</span>
              <span className="stat-value">{game.passing_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Completions</span>
              <span className="stat-value">{game.completions || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Attempts</span>
              <span className="stat-value">{game.attempts || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Completion %</span>
              <span className="stat-value">{completionPercentage}%</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yards Per Attempt</span>
              <span className="stat-value">{yardsPerAttempt}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Air Yards</span>
              <span className="stat-value">{game.passing_air_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Air Yards Share</span>
              <span className="stat-value">{airYardsShare}%</span>
            </div>
          </>
        );
      case 'Rush Yards':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Rushing Yards</span>
              <span className="stat-value">{game.rushing_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Carries</span>
              <span className="stat-value">{game.carries || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yards Per Carry</span>
              <span className="stat-value">{yardsPerCarry}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Rushing Touchdowns</span>
              <span className="stat-value">{game.rushing_tds || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Rushing First Downs</span>
              <span className="stat-value">{game.rushing_first_downs || 0}</span>
            </div>
          </>
        );
      case 'Rush + Receiving Yards':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Rushing Yards</span>
              <span className="stat-value">{game.rushing_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Receiving Yards</span>
              <span className="stat-value">{game.receiving_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Total Yards</span>
              <span className="stat-value">{totalYards}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Total Touches</span>
              <span className="stat-value">{totalTouches}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yards Per Touch</span>
              <span className="stat-value">{yardsPerTouch}</span>
            </div>
          </>
        );
      case 'Receiving Yards':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Receiving Yards</span>
              <span className="stat-value">{game.receiving_yards || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Targets</span>
              <span className="stat-value">{game.targets || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Receptions</span>
              <span className="stat-value">{game.receptions || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Catch Rate</span>
              <span className="stat-value">{catchRate}%</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yards Per Catch</span>
              <span className="stat-value">{yardsPerCatch}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Air Yards</span>
              <span className="stat-value">{game.receiving_air_yards || 0}</span>
            </div>
          </>
        );
      case 'Receptions':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Receptions</span>
              <span className="stat-value">{game.receptions || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Targets</span>
              <span className="stat-value">{game.targets || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yards Per Reception</span>
              <span className="stat-value">{avgYardsPerReception}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Receiving First Downs</span>
              <span className="stat-value">{game.receiving_first_downs || 0}</span>
            </div>
          </>
        );
      case 'Pass Attempts':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Pass Attempts</span>
              <span className="stat-value">{game.attempts || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Completions</span>
              <span className="stat-value">{game.completions || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Completion %</span>
              <span className="stat-value">{completionPercentage}%</span>
            </div>
          </>
        );
      case 'Pass Completions':
        return (
          <>
            <div className="stat-box">
              <span className="stat-label">Pass Completions</span>
              <span className="stat-value">{game.completions || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Pass Attempts</span>
              <span className="stat-value">{game.attempts || 0}</span>
            </div>
            <div className="stat-box">
              <span className="stat-label">Completion %</span>
              <span className="stat-value">{completionPercentage}%</span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="nfl-expanded-row">
      {isLoading ? (
        <div className="loading-message">Loading...</div>
      ) : (
        filteredPlayerStats && Object.keys(filteredPlayerStats.statsBySeason).length > 0 ? (
          <div className="player-stats-container">
            {/* Display confidence score */}
            <div className="aggregated-stats">
              <h4>Confidence Score</h4>
              <div className="condensed-stat">
                <span className="stat-label">Confidence Level:</span>
                <span className="stat-value">{confidenceScore}%</span>
              </div>
            </div>

            {/* 2023 Season Averages */}
            <div className="aggregated-stats smaller">
              <h4>2023 Season Average</h4>
              <div className="condensed-stat">
                <span className="stat-label">Avg {odds.Prop} (2023):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2023[`avg${odds.Prop.replace(/ /g, '')}`] || 0}</span>
              </div>
              <div className="condensed-stat">
                <span className="stat-label">Avg Target Share (2023):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2023.avgTargetShare}%</span>
              </div>
              <div className="condensed-stat">
                <span className="stat-label">Avg Air Yards Share (2023):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2023.avgAirYardsShare}%</span>
              </div>
            </div>

            {/* 2024 Season Averages */}
            <div className="aggregated-stats">
              <h4>2024 Season Averages</h4>
              <div className="condensed-stat">
                <span className="stat-label">Avg {odds.Prop} (2024):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2024[`avg${odds.Prop.replace(/ /g, '')}`] || 0}</span>
              </div>
              <div className="condensed-stat">
                <span className="stat-label">Avg Target Share (2024):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2024.avgTargetShare}%</span>
              </div>
              <div className="condensed-stat">
                <span className="stat-label">Avg Air Yards Share (2024):</span>
                <span className="stat-value">{filteredPlayerStats.averageStats2024.avgAirYardsShare}%</span>
              </div>
            </div>

            {/* Averages vs Opponent */}
            <div className="aggregated-stats">
              <h4>Averages vs {odds.opp}</h4>
              <div className="condensed-stat">
                <span className="stat-label">Avg {odds.Prop} vs {odds.opp}:</span>
                <span className="stat-value">{filteredPlayerStats.averageOpponentStats[`avg${odds.Prop.replace(/ /g, '')}`] || 0}</span>
              </div>
            </div>

            {/* Display stats for each season */}
            <div className="season-game-logs">
              {['2023 Regular Season', '2023 Playoffs', '2024 Regular Season'].map(season => {
                // Only display Playoffs if the player has them
                if (season === '2023 Playoffs' && !filteredPlayerStats.hasPlayoffs) {
                  return null;
                }

                return (
                  <div key={season} className="scrollable-season-container">
                    <h4>{season} Game Logs</h4>
                    <div className="player-game-stats">
                      {filteredPlayerStats.statsBySeason[season] && filteredPlayerStats.statsBySeason[season].map((game, idx) => (
                        <div key={idx} className="compact-game-stat">
                          <div className="game-week-label">Week {game.week} - {game.opponent_team}</div>
                          {renderPropStats(game)}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="loading-message">No stats available for this player.</div>
        )
      )}
    </div>
  );
};

export default NFLExpandedRow;
