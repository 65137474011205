import React from 'react';
import Sidebar from './Sidebar';
import './Dashboard.css';
import FiltersBar from './FiltersBar';

const Dashboard = ({
  filters,
  handleFilterChange,
  sport,
  propCounts,
  availableSportsbooks,
  subscriptionType,
  children,
  sidebarOpen,
  visibleSportsbooks,
  toggleSidebar,
  toggleSportsbookVisibility,
  teams 
}) => {
  return (
    <div className={`dashboard-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
      
      <div className={`content-container ${sidebarOpen ? 'pl-64' : 'pl-0'}`}>
        {/* Move FiltersBar to be above the main content */}
        <FiltersBar
          filters={filters}
          handleFilterChange={handleFilterChange}
          availableSportsbooks={availableSportsbooks}
          visibleSportsbooks={visibleSportsbooks}
          toggleSportsbookVisibility={toggleSportsbookVisibility}
          sport={sport}
          propCounts={propCounts}
          teams={teams} // Pass unique teams here

        />
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
