import React, { useMemo } from 'react';
import './WNBAExpandedRow.css';

const WNBAExpandedRow = ({ odds, playerStats, teammatesOut, leagueAverages }) => {
  const weights = {
    recentPerformance: 0.4,    // 40%
    opponentPerformance: 0.2,  // 20%
    consistency: 0.2,          // 20%
    minutesPlayed: 0.1,        // 10%
    teamDynamics: 0.05,        // 5%
    turnoversPlusMinus: 0.05   // 5%
  };

  const calculateAverage = (games, propKey) => {
    const validGames = games.filter(game => game[propKey] !== undefined && !isNaN(parseFloat(game[propKey])));
    if (validGames.length === 0) return leagueAverages[propKey] || 0; 

    const total = validGames.reduce((sum, game) => sum + parseFloat(game[propKey]), 0);
    return (total / validGames.length).toFixed(2);
  };

  const calculateStandardDeviation = (games, propKey) => {
    const validGames = games.filter(game => game[propKey] !== undefined && !isNaN(parseFloat(game[propKey])));
    if (validGames.length === 0) return 0;

    const mean = calculateAverage(validGames, propKey);
    const variance = validGames.reduce((sum, game) => {
      const diff = parseFloat(game[propKey]) - mean;
      return sum + diff * diff;
    }, 0) / validGames.length;

    return Math.sqrt(variance).toFixed(2);
  };

  const convertMinutesToDecimal = (minutes) => {
    const [min, sec] = minutes.split(':').map(parseFloat);
    return min + sec / 60;
  };

  const calculateAverageMinutes = (games) => {
    const validGames = games.filter(game => game.MP);
    if (validGames.length === 0) return leagueAverages['MP'] || 0;
    const totalMinutes = validGames.reduce((sum, game) => sum + convertMinutesToDecimal(game.MP || '0:00'), 0);
    return (totalMinutes / validGames.length).toFixed(2);
  };

  let propKey, propName;
  switch (odds.Prop) {
    case 'Points':
      propKey = 'PTS';
      propName = 'Points';
      break;
    case 'Rebounds':
      propKey = 'TRB';
      propName = 'Rebounds';
      break;
    case 'Assists':
      propKey = 'AST';
      propName = 'Assists';
      break;
    default:
      propKey = 'PTS';
      propName = 'Points';
  }

  const overallAverage = calculateAverage(playerStats, propKey);
  const sortedPlayerStats = [...playerStats].sort((a, b) => new Date(b.Date) - new Date(a.Date));
  
  const uniqueDates = new Set();
  const last5Games = sortedPlayerStats.filter(game => {
    if (!uniqueDates.has(game.Date)) {
      uniqueDates.add(game.Date);
      return true;
    }
    return false;
  }).slice(0, 5);

  const last5GamesAverage = calculateAverage(last5Games, propKey);
  const last5GamesStdDev = calculateStandardDeviation(last5Games, propKey);
  const opponent = odds.opp.replace('@', '').trim();
  const opponentGames = playerStats.filter(game => game.Opponent === opponent);

  const uniqueOpponentDates = new Set();
  const uniqueOpponentGames = opponentGames.filter(game => {
    if (!uniqueOpponentDates.has(game.Date)) {
      uniqueOpponentDates.add(game.Date);
      return true;
    }
    return false;
  });

  const avgVsOpponent = uniqueOpponentGames.length > 0 ? calculateAverage(uniqueOpponentGames, propKey) : 'No Data';
  const overallAvgMinutes = calculateAverageMinutes(playerStats);
  const last5GamesAvgMinutes = calculateAverageMinutes(last5Games);

  const getPropLine = () => {
    return odds.fanduel || odds.draftkings || odds.mgm || odds.betrivers;
  };

  const propLine = parseFloat(getPropLine()?.split(' ')[0]?.replace(/[()]/g, '') || 0);

  const aggregateStats = useMemo(() => ({
    overallAvg: parseFloat(overallAverage || 0),
    last5Avg: parseFloat(last5GamesAverage || 0),
    vsOpponentAvg: avgVsOpponent,
    recentMinutes: parseFloat(last5GamesAvgMinutes || 0),
    last5StdDev: parseFloat(last5GamesStdDev || 0),  // Consistency measure
  }), [overallAverage, last5GamesAverage, avgVsOpponent, last5GamesAvgMinutes, last5GamesStdDev]);

  const evaluateWNBAConfidenceLevel = useMemo(() => {
    let overScore = 0;
    let underScore = 0;
    let explanationOver = '';
    let explanationUnder = '';

    if (aggregateStats.last5Avg > propLine) {
      overScore += weights.recentPerformance;
      explanationOver += `Recent performance of ${aggregateStats.last5Avg} is above the prop line. `;
    } else {
      underScore += weights.recentPerformance;
      explanationUnder += `Recent performance of ${aggregateStats.last5Avg} is below the prop line. `;
    }

    if (aggregateStats.vsOpponentAvg !== 'No Data') {
      if (aggregateStats.vsOpponentAvg > propLine) {
        overScore += weights.opponentPerformance;
        explanationOver += `Player performs better against ${opponent}, averaging ${aggregateStats.vsOpponentAvg}. `;
      } else {
        underScore += weights.opponentPerformance;
        explanationUnder += `Player performs worse against ${opponent}, averaging ${aggregateStats.vsOpponentAvg}. `;
      }
    } else {
      explanationOver += `No previous data available against ${opponent}. `;
      explanationUnder += `No previous data available against ${opponent}. `;
    }

    if (aggregateStats.recentMinutes > 30) {
      overScore += weights.minutesPlayed;
      explanationOver += `Player plays significant minutes (${aggregateStats.recentMinutes}). `;
    } else {
      underScore += weights.minutesPlayed;
      explanationUnder += `Player plays limited minutes (${aggregateStats.recentMinutes}). `;
    }

    if (aggregateStats.last5StdDev < 5) {
      overScore += weights.consistency;
      explanationOver += `Player shows consistent performance (std dev ${aggregateStats.last5StdDev}). `;
    } else {
      underScore += weights.consistency;
      explanationUnder += `Player shows inconsistent performance (std dev ${aggregateStats.last5StdDev}). `;
    }

    const overConfidence = 
      overScore >= 0.7 ? 'Very High' : 
      overScore >= 0.5 ? 'High' : 
      overScore >= 0.3 ? 'Medium' : 
      overScore >= 0.1 ? 'Low' : 'Very Low';

    const underConfidence = 
      underScore >= 0.7 ? 'Very High' : 
      underScore >= 0.5 ? 'High' : 
      underScore >= 0.3 ? 'Medium' : 
      underScore >= 0.1 ? 'Low' : 'Very Low';

    explanationOver += `Final confidence level for Over: ${overConfidence}`;
    explanationUnder += `Final confidence level for Under: ${underConfidence}`;

    return {
      overConfidence,
      underConfidence,
      explanationOver,
      explanationUnder,
    };
  }, [aggregateStats, propLine, teammatesOut]);

  const { overConfidence, underConfidence, explanationOver, explanationUnder } = evaluateWNBAConfidenceLevel;

  const getConfidenceColor = (confidence) => {
    switch (confidence) {
      case 'Very High':
        return 'very-high'; 
      case 'High':
        return 'high'; 
      case 'Medium':
        return 'medium'; 
      case 'Low':
        return 'low'; 
      case 'Very Low':
        return 'very-low'; 
      default:
        return 'neutral'; 
    }
  };
  
  const confidenceColor = odds.Over_Under === 'Over' 
    ? getConfidenceColor(overConfidence) 
    : getConfidenceColor(underConfidence);

  const explanation = odds.Over_Under === 'Over'
    ? explanationOver
    : explanationUnder;

  return (
    <div className="expanded-details">
      <h4>{odds.PlayerName} - Game Logs</h4>
      <div className="prop-summary">
        <div className="summary-section">
          <h5>Overall Averages</h5>
          <p>{propName}: {overallAverage}</p>
          <p>Minutes: {overallAvgMinutes}</p>
        </div>
        <div className="summary-section">
          <h5>Last 5 Games</h5>
          <p>{propName}: {last5GamesAverage}</p>
          <p>Minutes: {last5GamesAvgMinutes}</p>
        </div>
        <div className="summary-section">
          <h5>Averages vs {opponent}</h5>
          <p>{propName}: {avgVsOpponent}</p>
        </div>
      </div>

      <div className={`confidence-indicator ${confidenceColor}`}>
        <strong>Confidence Level for {odds.Over_Under} {propLine}: {odds.Over_Under === 'Over' ? overConfidence : underConfidence}</strong>
        <p>{explanation}</p>
      </div>

      <table className="expanded-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Opponent</th>
            <th>Minutes Played</th>
            <th>{propName}</th>
          </tr>
        </thead>
        <tbody>
          {last5Games.map((game, index) => (
            <tr key={index}>
              <td>{game.Date}</td>
              <td>{game.Opponent}</td>
              <td>{game.MP}</td>
              <td>{game[propKey]}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="table-container">
        <h5>Games vs {opponent}</h5>
        <table className="expanded-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Opponent</th>
              <th>Minutes Played</th>
              <th>{propName}</th>
            </tr>
          </thead>
          <tbody>
            {uniqueOpponentGames.map((game, index) => (
              <tr key={index}>
                <td>{game.Date}</td>
                <td>{game.Opponent}</td>
                <td>{game.MP}</td>
                <td>{game[propKey]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WNBAExpandedRow;
