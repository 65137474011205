import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEnvelope, FaLock, FaPhone } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'daisyui/dist/full.css'; // Import daisyUI styles
import './LoginPage.css'; // Import the CSS for flip animation

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false); // State to handle card flip
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [signupPhoneNumber, setSignupPhoneNumber] = useState('');
  const [signupConsent, setSignupConsent] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false); // New state for signup password visibility


  const navigate = useNavigate(); // Initialize navigate


  const toggleSignupPasswordVisibility = () => {
    setShowSignupPassword(!showSignupPassword);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateEmail(email)) {
      toast.error('Email is invalid');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post('/login', {
        email,
        password,
      });
      if (response.data.status === 'success') {
        toast.success('Login successful!');
        onLogin(); // Call the onLogin function to update the state in App.js
        if (rememberMe) {
          localStorage.setItem('email', email);
        } else {
          localStorage.removeItem('email');
        }
        // Use navigate to go to /mlb route
        navigate('/mlb');
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error('Invalid Credentials. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateEmail(signupEmail)) {
      toast.error('Email is invalid');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post('/register', {
        email: signupEmail,
        password: signupPassword,
        phone_number: signupPhoneNumber,
        sms_opt_in: signupConsent,
      });
      if (response.data.status === 'success') {
        toast.success('Signup successful!');
        setIsFlipped(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  
  return (
    <div className="login-page">
      {/* Left Section */}
      <div className="login-section">

        <div className="login-left-section">
          <div className="login-left-content">
            <h2 className="text-4xl font-extrabold rotate-in">Welcome to Prop Scout!</h2>
            <p className="text-lg font-light rotate-in">
              Track the latest sports betting odds with ease for a wide variety of player props available on DFS betting platforms.
            </p>
            <div className="computer-mockup">
              <img src="/images/computer_image.png" alt="Computer Mockup" className="w-full h-auto bounce-in" />
              <div className="mockup-content">
                <img src="/images/screen.png" alt="Site Preview" className="w-full h-auto rotate-in" />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="login-right-section">
          <div className="relative w-full max-w-md">
            <div className={`flip-card ${isFlipped ? 'flip' : ''}`}>
              <div className="flip-card-inner">
                {/* Login Form */}
                <div className="flip-card-front flex items-center justify-center">
                  <div className="card-content w-full">
                    <div className="text-center mb-6">
                      <img src="/images/logo.png" alt="Logo" className="mx-auto w-24 h-24" />
                      <h2 className="text-3xl font-bold text-gray-900 mt-4">Login</h2>
                    </div>
                    <form onSubmit={handleLoginSubmit} className="space-y-4">
                      {/* Email Field */}
                      <div className="form-group">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            <FaEnvelope />
                          </span>
                          <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-input input-bordered w-full pl-10"
                          />
                        </div>
                      </div>

                      {/* Password Field */}
                      <div className="form-group">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            <FaLock />
                          </span>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input input-bordered w-full pl-10 pr-10"
                          />
                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                      </div>

                      {/* Remember Me and Forgot Password */}
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900">Remember me</label>
                        </div>
                        <div className="text-sm">
                          <a href="/forgot-password" className="font-medium text-blue-600 hover:text-blue-500">Forgot your password?</a>
                        </div>
                      </div>

                      {/* Login Button */}
                      <div className="form-group">
                        {loading ? (
                          <div className="flex justify-center">
                            <span className="loading loading-spinner loading-lg"></span>
                          </div>
                        ) : (
                          <button type="submit" className="login-btn btn btn-primary w-full">Login</button>
                        )}
                      </div>

                      {/* Signup Link */}
                      <div className="text-center">
                        <p className="text-sm text-gray-600">
                          Don't have an account? 
                          <span onClick={() => setIsFlipped(true)} className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer">
                            Sign Up here
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>

                {/* Signup Form */}
                <div className="flip-card-back flex items-center justify-center">
                  <div className="card-content w-full">
                    <div className="text-center mb-6">
                      <img src="https://via.placeholder.com/100" alt="Logo" className="mx-auto w-24 h-24" />
                      <h2 className="text-3xl font-bold text-gray-900 mt-4">Sign Up</h2>
                    </div>
                    <form onSubmit={handleSignupSubmit} className="space-y-4">
                      {/* Signup Email Field */}
                      <div className="form-group">
                        <label htmlFor="signupEmail" className="block text-sm font-medium text-gray-700">Email</label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            <FaEnvelope />
                          </span>
                          <input
                            type="email"
                            id="signupEmail"
                            value={signupEmail}
                            onChange={(e) => setSignupEmail(e.target.value)}
                            required
                            className="login-input input-bordered w-full pl-10"
                          />
                        </div>
                      </div>

                      {/* Signup Password Field */}
                      <div className="form-group">
                        <label htmlFor="signupPassword" className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            <FaLock />
                          </span>
                          <input
                            type={showSignupPassword ? 'text' : 'password'}
                            id="signupPassword"
                            value={signupPassword}
                            onChange={(e) => setSignupPassword(e.target.value)}
                            required
                            className="login-input input-bordered w-full pl-10 pr-10"
                          />
                          <button
                            type="button"
                            onClick={toggleSignupPasswordVisibility}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                          >
                            {showSignupPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                      </div>

                      {/* Signup Phone Number Field */}
                      <div className="form-group">
                        <label htmlFor="signupPhoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            <FaPhone />
                          </span>
                          <input
                            type="tel"
                            id="signupPhoneNumber"
                            value={signupPhoneNumber}
                            onChange={(e) => setSignupPhoneNumber(e.target.value)}
                            required
                            className="login-input input-bordered w-full pl-10"
                          />
                        </div>
                      </div>

                      {/* Signup Consent */}
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="signupConsent"
                          checked={signupConsent}
                          onChange={(e) => setSignupConsent(e.target.checked)}
                          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="signupConsent" className="ml-2 block text-sm text-gray-900">
                          I agree to receive SMS notifications
                        </label>
                      </div>

                      {/* Signup Button */}
                      <div className="form-group">
                        {loading ? (
                          <div className="flex justify-center">
                            <span className="loading loading-spinner loading-lg"></span>
                          </div>
                        ) : (
                          <button type="submit" className="login-btn btn btn-primary w-full">Sign Up</button>
                        )}
                      </div>

                      {/* Login Link */}
                      <div className="text-center">
                        <p className="text-sm text-gray-600">
                          Already have an account? 
                          <span onClick={() => setIsFlipped(false)} className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer">
                            Login here
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
      {/* <div className="feature-section">
        <div className="text-content">
          <h2 className="headline">A data-driven solution for sports bettors looking for an edge</h2>
          <p className="subheadline">
            We offer a clean and quick dashboard for profitable PrizePicks and Underdog props!
          </p>
          <button className="cta-button">Try Now For FREE</button>
        </div>
        <div className="mockup-container">
          <img src="/images/dashboard_mockup.png" alt="Dashboard Mockup" className="dashboard-mockup" />
        </div>
      </div> */}
      <ToastContainer />
    </div>
  );
  
};


export default LoginPage;
