import React from 'react';
import './FiltersBar.css';

const FiltersBar = ({
  filters,
  handleFilterChange,
  availableSportsbooks = [],
  visibleSportsbooks = [],
  toggleSportsbookVisibility,
  sport,
  propCounts,
  teams = [], // Receive teams as a prop
}) => {

  const handleMultiSelectChange = (team) => {
    const selectedTeams = filters.teamOpponent.includes(team)
      ? filters.teamOpponent.filter(t => t !== team)
      : [...filters.teamOpponent, team];

    handleFilterChange({
      target: {
        name: 'teamOpponent',
        value: selectedTeams,
      }
    });
  };

  return (
    <div className="filters-bar">
      {/* Filter by Player */}
      <div className="filter-section">
        <label>Filter by Player:</label>
        <input
          type="text"
          name="playerName"
          placeholder="Enter player name"
          onChange={handleFilterChange}
          value={filters.playerName}
          className="filter-input"
        />
      </div>

      {/* Filter by Team */}
      <div className="filter-section filter-by-team">
        <label>Filter by Team:</label>
        <div className="team-checkboxes">
          {teams.map((team) => (
            <label key={team} className="team-checkbox-label">
              <input
                type="checkbox"
                value={team}
                checked={filters.teamOpponent.includes(team)}
                onChange={() => handleMultiSelectChange(team)}
              />
              {team}
            </label>
          ))}
        </div>
      </div>

      {/* Filter by Prop */}
      <div className="filter-section">
        <label>Filter by Prop:</label>
        <select
          name="propType"
          onChange={handleFilterChange}
          value={filters.propType}
          className="custom-select"
        >
          {sport === 'mlb' ? (
            <>
              <option value="all">All ({propCounts.all})</option>
              <option value="Runs">Runs ({propCounts.Runs})</option>
              <option value="Strikeouts">Strikeouts ({propCounts.Strikeouts})</option>
              <option value="Total Bases">Total Bases ({propCounts['Total Bases']})</option>
            </>
          ) : sport === 'nba' || sport === 'wnba' ? (
            <>
              <option value="all">All ({propCounts.all})</option>
              <option value="Points">Points ({propCounts.Points})</option>
              <option value="Rebounds">Rebounds ({propCounts.Rebounds})</option>
              <option value="Assists">Assists ({propCounts.Assists})</option>
              <option value="3-Pointers Made">3-Pointers Made ({propCounts['3-Pointers Made']})</option>
            </>
          ) : sport === 'nfl' ? (
            <>
              <option value="all">All ({propCounts.all})</option>
              <option value="Rush Yards">Rush Yards ({propCounts['Rush Yards']})</option>
              <option value="Passing Yards">Passing Yards ({propCounts['Passing Yards']})</option>
              <option value="Rush + Receiving Yards">Rush + Receiving Yards ({propCounts['Rush + Receiving Yards']})</option>
              <option value="Receiving Yards">Receiving Yards ({propCounts['Receiving Yards']})</option>
              <option value="Receptions">Receptions ({propCounts.Receptions})</option>
              <option value="Pass Attempts">Pass Attempts ({propCounts['Pass Attempts']})</option>
              <option value="Pass Completions">Pass Completions ({propCounts['Pass Completions']})</option>
            </>
          ) : null}
        </select>
      </div>

      {/* Sportsbooks */}
      <div className="filter-section">
        <label>Sportsbooks:</label>
        <div className="sportsbooks-checkboxes">
          {availableSportsbooks.map((book) => (
            <label key={book} className="dropdown-label">
              <input
                type="checkbox"
                checked={visibleSportsbooks.includes(book)}
                onChange={() => toggleSportsbookVisibility(book)}
              />
              <span>{book}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FiltersBar;
