import React from 'react';
import MLBExpandedRow from './MLBExpandedRow';
import WNBAExpandedRow from './WNBAExpandedRow';
import NFLExpandedRow from './NFLExpandedRow';

const ExpandedRow = ({ odds, propType, playerStats, sport, lineupsData,teammatesOut, teamStats }) => {

  switch (sport) {
    case 'mlb':
      return <MLBExpandedRow odds={odds} playerStats={playerStats} lineupsData={lineupsData}/>;
    // Add other sports' cases here
    case 'wnba':
      return <WNBAExpandedRow odds={odds} propType={odds.Prop} playerStats={playerStats} teammatesOut={teammatesOut} teamStats = {teamStats} lineupsData={lineupsData}/>;
    case 'nfl':
      return <NFLExpandedRow odds={odds} playerStats={playerStats} lineupsData={lineupsData} />;  // Render NFL data
  }
};

export default ExpandedRow;
