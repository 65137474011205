import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const HistoricalChart = ({ data, dataFilter, sport }) => {
  const chartRef = useRef(null);
  const historicalChartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const themeColors = {
      mlb: {
        backgroundColor: '#001f3f',
        borderColor: 'rgba(255, 99, 132, 1)',
        draftkings: 'rgba(255, 99, 132, 1)',
        fanduel: 'rgba(54, 162, 235, 1)',
        mgm: 'rgba(75, 192, 192, 1)',
        betrivers: 'rgba(153, 102, 255, 1)',
        pinnacle: 'rgba(255, 206, 86, 1)',
      },
      wnba: {
        backgroundColor: '#3f1f00',
        borderColor: 'rgba(255, 69, 0, 1)',
        draftkings: 'rgba(255, 99, 132, 1)',
        fanduel: 'rgba(54, 162, 235, 1)',
        mgm: 'rgba(75, 192, 192, 1)',
        betrivers: 'rgba(153, 102, 255, 1)',
        pinnacle: 'rgba(255, 206, 86, 1)',
      }
    };

    const theme = themeColors[sport] || themeColors.mlb;

    const extractOddsValue = (value) => {
      if (!value || value === 'None' || value === 'N/A') return null;
      const parts = value.split(' ');
      return parts.length > 1 ? parseFloat(parts[1]) : null;
    };

    const filteredData = data.slice(-dataFilter);
    const timestamps = filteredData.map(item => new Date(item.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    const draftkings = filteredData.map(item => extractOddsValue(item.draftkings));
    const fanduel = filteredData.map(item => extractOddsValue(item.fanduel));
    const mgm = filteredData.map(item => extractOddsValue(item.mgm));
    const betrivers = filteredData.map(item => extractOddsValue(item.betrivers));
    const pinnacle = filteredData.map(item => extractOddsValue(item.pinnacle));

    if (historicalChartRef.current) {
      historicalChartRef.current.destroy();
    }

    historicalChartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: timestamps,
        datasets: [
          {
            label: 'DraftKings',
            data: draftkings,
            borderColor: theme.draftkings,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
          {
            label: 'FanDuel',
            data: fanduel,
            borderColor: theme.fanduel,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
          {
            label: 'MGM',
            data: mgm,
            borderColor: theme.mgm,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
          {
            label: 'BetRivers',
            data: betrivers,
            borderColor: theme.betrivers,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
          {
            label: 'Pinnacle',
            data: pinnacle,
            borderColor: theme.pinnacle,
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: '#fff',
              font: {
                size: 14,
              },
            },
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.raw !== null ? context.raw : 'N/A';
                const propLine = {
                  'DraftKings': draftkings,
                  'FanDuel': fanduel,
                  'MGM': mgm,
                  'BetRivers': betrivers,
                  'Pinnacle': pinnacle
                }[context.dataset.label][context.dataIndex];
                return `${label} ${propLine}`;
              }
            },
            backgroundColor: 'rgba(0,0,0,0.8)',
            titleColor: '#fff',
            bodyColor: '#fff',
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Time',
              color: '#fff',
              font: {
                family: 'Arial',
                size: 16,
                weight: 'bold',
                lineHeight: 1.2,
              },
              padding: { top: 20, left: 0, right: 0, bottom: 0 }
            },
            ticks: {
              color: '#fff',
              maxRotation: 45,
              minRotation: 45,
              autoSkip: true,
              maxTicksLimit: 10,
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Odds',
              color: '#fff',
              font: {
                family: 'Arial',
                size: 16,
                weight: 'bold',
                lineHeight: 1.2,
              },
              padding: { top: 30, left: 0, right: 0, bottom: 0 }
            },
            ticks: {
              color: '#fff',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

  }, [data, dataFilter, sport]);

  return (
    <canvas ref={chartRef} id="historicalChart"></canvas>
  );
};

export default HistoricalChart;
