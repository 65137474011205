import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = ({ 
  filters, 
  handleFilterChange, 
  sport, 
  propCounts, 
  availableSportsbooks, 
  subscriptionType, 
  sidebarOpen, 
  toggleSidebar, 
  visibleSportsbooks, 
  toggleSportsbookVisibility 
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState({
    player: true,
    game: false,
    prop: true,
    sportsbook: false
  });

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  const toggleFilterSection = (section) => {
    setFiltersOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <div className={`filters-sidebar ${sidebarOpen ? 'open' : 'collapsed'}`}>
      <button className="toggle-sidebar" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={sidebarOpen ? faTimes : faBars} />
      </button>
      <div className="sidebar-content">
        <div className="filter">
          <label onClick={() => toggleFilterSection('player')}>
            Filter by Player:
            <FontAwesomeIcon icon={filtersOpen.player ? faChevronUp : faChevronDown} className="chevron-icon" />
          </label>
          {filtersOpen.player && (
            <input
              type="text"
              id="player-name"
              name="playerName"
              placeholder="Enter player name"
              onChange={handleFilterChange}
              value={filters.playerName}
              className="filter-input"
            />
          )}
        </div>

        <div className="filter">
          <label onClick={() => toggleFilterSection('game')}>
            Filter by Game:
            <FontAwesomeIcon icon={filtersOpen.game ? faChevronUp : faChevronDown} className="chevron-icon" />
          </label>
          {filtersOpen.game && (
            <input
              type="text"
              id="team-opponent"
              name="teamOpponent"
              placeholder="Enter team vs opponent"
              onChange={handleFilterChange}
              value={filters.teamOpponent}
              className="filter-input"
            />
          )}
        </div>

        <div className="filter">
          <label onClick={() => toggleFilterSection('prop')}>
            Filter by Prop:
            <FontAwesomeIcon icon={filtersOpen.prop ? faChevronUp : faChevronDown} className="chevron-icon" />
          </label>
          {filtersOpen.prop && (
            <select
              id="prop-type"
              name="propType"
              onChange={handleFilterChange}
              value={filters.propType}
              className="custom-select"
            >
              {sport === 'mlb' ? (
                <>
                  <option value="all">All ({propCounts.all})</option>
                  <option value="Runs">Runs ({propCounts.Runs})</option>
                  <option value="Strikeouts">Strikeouts ({propCounts.Strikeouts})</option>
                  <option value="Total Bases">Total Bases ({propCounts['Total Bases']})</option>
                </>
              ) : (
                <>
                  <option value="all">All ({propCounts.all})</option>
                  <option value="Points">Points ({propCounts.Points})</option>
                  <option value="Rebounds">Rebounds ({propCounts.Rebounds})</option>
                  <option value="Assists">Assists ({propCounts.Assists})</option>
                  <option value="3-Pointers Made">3-Pointers Made ({propCounts['3-Pointers Made']})</option>
                </>
              )}
            </select>
          )}
        </div>

        <div className="filter">
          <label onClick={() => toggleFilterSection('sportsbook')}>
            Sportsbooks:
            <FontAwesomeIcon icon={filtersOpen.sportsbook ? faChevronUp : faChevronDown} className="chevron-icon" />
          </label>
          {filtersOpen.sportsbook && (
            <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
              <button className="dropdown-btn" onClick={toggleDropdown}>
                Select Sportsbooks
                <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} />
              </button>
              {dropdownOpen && (
                <div className="dropdown-content" onMouseLeave={closeDropdown}>
                  {availableSportsbooks.map(book => (
                    <label key={book} className="dropdown-label">
                      <input
                        type="checkbox"
                        checked={visibleSportsbooks.includes(book)}
                        onChange={() => toggleSportsbookVisibility(book)}
                      />
                      <span>{book}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
