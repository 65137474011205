import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from './components/Navbar';
import OddsPage from './pages/OddsPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import SubscribePage from './pages/SubscribePage';
import Dashboard from './components/Dashboard';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import TopProps from './pages/TopProps';

const App = () => {
  const [lastUpdated, setLastUpdated] = useState('N/A');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sport, setSport] = useState('mlb');
  const [showNotification, setShowNotification] = useState(false);
  const [favorites, setFavorites] = useState({ mlb: [], wnba: [] });
  const [filteredData, setFilteredData] = useState([]);
  const [avatar, setAvatar] = useState('/static/uploads/avatars/default-avatar.png');
  const [subscriptionType, setSubscriptionType] = useState('free');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const [propCounts, setPropCounts] = useState({});
  const [oddsData, setOddsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    playerName: '',
    teamOpponent: [],
    propType: 'all',
    sortBy: 'default'
  });

  const sportsbooksPerSport = {
    mlb: ['DraftKings', 'FanDuel', 'MGM', 'BetRivers', 'Pinnacle'],
    wnba: ['DraftKings', 'FanDuel', 'MGM', 'BetRivers'],
    nfl: ['DraftKings', 'FanDuel', 'MGM', 'BetRivers'],
    nba: ['DraftKings', 'FanDuel', 'MGM', 'BetRivers', 'Pinnacle'],
  };
  
  const [availableSportsbooks, setAvailableSportsbooks] = useState(sportsbooksPerSport[sport]);
  const [visibleSportsbooks, setVisibleSportsbooks] = useState(availableSportsbooks);

  const isLoginPage = location.pathname === '/login';

  const toggleSportsbookVisibility = (sportsbook) => {
    setVisibleSportsbooks(prev => 
      prev.includes(sportsbook) 
        ? prev.filter(s => s !== sportsbook) 
        : [...prev, sportsbook]
    );
  };

  const handleFilterChange = (event) => {
    const { name, value, options } = event.target;

    if (name === 'teamOpponent' && options) {
        const selectedValues = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: selectedValues
        }));
    } else {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }

    setCurrentPage(1); 
  };

  const updateLastUpdated = (date) => {
    setLastUpdated(date);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    navigate('/mlb');  // Navigate to /mlb after logging in
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setIsLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleReload = () => {
    setShowNotification(false);
    fetchOdds();
  };

  const fetchFavoriteProps = async (sport) => {
    try {
      const response = await fetch(`/get_favorite_props?sport=${sport}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const favoriteProps = await response.json();
        const newFavorites = { mlb: [], wnba: [] };
        favoriteProps.forEach(fav => {
          newFavorites[sport].push(`${fav.player_name}${fav.prop}${fav.over_under}`);
        });
        setFavorites(prevFavorites => ({ ...prevFavorites, ...newFavorites }));
      }
    } catch (error) {
      console.error('Error fetching favorite props:', error);
    }
  };

  const calculatePropCounts = (data, sport) => {
    const counts = {
      all: data.length,
      Runs: 0,
      Strikeouts: 0,
      'Total Bases': 0,
      Points: 0,
      Rebounds: 0,
      Assists: 0,
      '3-Pointers Made': 0,
      'Rush Yards': 0,
      'Passing Yards': 0,
      'Rush + Receiving Yards': 0,
      'Receiving Yards': 0,
      Receptions: 0,
      'Pass Attempts': 0,
      'Pass Completions': 0,
    };
  
    data.forEach((item) => {
      if (counts[item.Prop] !== undefined) {
        counts[item.Prop]++;
      }
    });
  
    return counts;
  };

  const applyFilters = (data, filters) => {
    let filtered = data;

    if (filters.playerName) {
        filtered = filtered.filter(odds => odds.PlayerName.toLowerCase().includes(filters.playerName.toLowerCase()));
    }

    if (filters.teamOpponent && filters.teamOpponent.length > 0) {
      filtered = filtered.filter(odds => {
        return filters.teamOpponent.some(opponent =>
          `${odds.team} vs. ${odds.opp}`.toLowerCase().includes(opponent.toLowerCase())
        );
      });
    }
    

    if (filters.propType !== 'all') {
        filtered = filtered.filter(odds => odds.Prop === filters.propType);
    }

    // Check for sportsbook data availability and filter accordingly
    filtered = filtered.map(odds => {
        const availableData = availableSportsbooks.filter(sportsbook => {
            const sportsbookValue = odds[sportsbook.toLowerCase()];
            return sportsbookValue && sportsbookValue !== 'None' && sportsbookValue !== 'N/A';
        });
        return {
            ...odds,
            availableSportsbooks: availableData
        };
    });

    if (filters.sortBy === 'impliedProbAsc') {
        filtered.sort((a, b) => parseFloat(a.Implied_Prob) - parseFloat(b.Implied_Prob));
    } else if (filters.sortBy === 'impliedProbDesc') {
        filtered.sort((a, b) => parseFloat(b.Implied_Prob) - parseFloat(a.Implied_Prob));
    } else if (filters.sortBy.endsWith('Asc')) {
        const sportsbook = filters.sortBy.replace('Asc', '').toLowerCase();
        filtered.sort((a, b) => parseFloat(a[sportsbook]?.split(' ')[1] || 'Infinity') - parseFloat(b[sportsbook]?.split(' ')[1] || 'Infinity'));
    } else if (filters.sortBy.endsWith('Desc')) {
        const sportsbook = filters.sortBy.replace('Desc', '').toLowerCase();
        filtered.sort((a, b) => parseFloat(b[sportsbook]?.split(' ')[1] || '-Infinity') - parseFloat(a[sportsbook]?.split(' ')[1] || '-Infinity'));
    }

    return filtered;
  };

  const fetchOdds = useCallback(async () => {
    if (isLoginPage || !isLoggedIn) return; // Skip fetching if on the login page or not logged in

    try {
        const response = await fetch(`/merged_data?sport=${sport}`);
        const data = await response.json();
        console.log("Odds data retrieved:", data);

        if (JSON.stringify(data) !== JSON.stringify(oddsData)) {
            setOddsData(data);
            const newFilteredData = applyFilters(data, filters);
            if (JSON.stringify(newFilteredData) !== JSON.stringify(filteredData)) {
                setFilteredData(newFilteredData);
                setCurrentPage(1);
            }
            const counts = calculatePropCounts(data);
            console.log(counts)
            setPropCounts(counts);
            updateLastUpdated(new Date());
        }
    } catch (error) {
        console.error('Error fetching odds data:', error);
    }
  }, [filters, oddsData, isLoginPage, isLoggedIn]);

  const sortData = (key) => {
    const sortOrder = filters.sortBy === `${key}Asc` ? 'Desc' : 'Asc';
    setFilters((prevFilters) => ({ ...prevFilters, sortBy: `${key}${sortOrder}` }));
  };

  useEffect(() => {
    if (isLoginPage) return;

    setFilters({
      playerName: '',
      teamOpponent: [],
      propType: 'all',
      sortBy: 'default',
    });
    setCurrentPage(1);
    const currentSportsbooks = sportsbooksPerSport[sport];
    setAvailableSportsbooks(currentSportsbooks);
    setVisibleSportsbooks(currentSportsbooks);  
  }, [sport, isLoginPage]);

  useEffect(() => {
    fetchOdds();
  }, [fetchOdds, sport]);

  useEffect(() => {
    if (isLoginPage || !isLoggedIn) return; // Skip fetching if on the login page or not logged in

    const newFilteredData = applyFilters(oddsData, filters);
    if (JSON.stringify(newFilteredData) !== JSON.stringify(filteredData)) {
        setFilteredData(newFilteredData);
    }
  }, [filters, oddsData, isLoginPage]);

  const formatDate = (date) => {
    return date.toLocaleString('en-US', {
      hour12: true,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', sport === 'mlb' ? 'forest' : sport === 'wnba' ? 'valentine' : sport === 'nfl' ? 'autumn' : 'synthwave');
  }, [sport]);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus) {
      setIsLoggedIn(true);
      fetchUserProfile();
    } else {
      setIsLoggedIn(false);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch('/get_user_profile', { credentials: 'include' });
      const data = await response.json();
      setSubscriptionType(data.subscriptionType);
      setAvatar(data.avatar);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    const sportFromPath = path.split('/')[1];
  
    // Redirect to a default sport (e.g., /mlb) if no sport is specified
    if (!sportFromPath) {
      navigate('/mlb');
    }
  
    if (isLoggedIn && !isLoginPage) {
      setSport(sportFromPath);
      fetchFavoriteProps(sportFromPath); 
    } else if (!loading) {
      navigate('/login');
    }
  }, [isLoggedIn, loading, location.pathname, isLoginPage]);

  useEffect(() => {
    if (isLoginPage || !isLoggedIn) return; // Skip fetching if on the login page or not logged in

    const checkTime = () => {
      const now = new Date();
      const lastUpdatedDate = new Date(lastUpdated);
      const diff = now - lastUpdatedDate;

      if (diff >= 30 * 60 * 1000) {
        toast.info('Props Updated! Refresh to see new data', {
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    };

    const interval = setInterval(checkTime, 60 * 1000);
    return () => clearInterval(interval);
  }, [lastUpdated, isLoginPage]);

  
  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  const extractUniqueTeams = (data) => {
    const teams = new Set();
    data.forEach(item => {
        if (!item.team.startsWith('@')) {
            teams.add(item.team);
        }
        if (!item.opp.startsWith('@')) {
            teams.add(item.opp);
        }
    });
    return Array.from(teams);
};

  const uniqueTeams = extractUniqueTeams(oddsData);

  return (
    <div className={`App ${sport}`}>
      {!isLoginPage && isLoggedIn && (
        <Navbar
          lastUpdated={lastUpdated ? formatDate(new Date(lastUpdated)) : 'N/A'}
          sport={sport}
          setSport={setSport}
          handleLogout={handleLogout}
          avatar={avatar}
          subscriptionType={subscriptionType}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)} 
        />
      )}

      <div className="page-wrapper">
        <div className="main-content">
          <Routes>
            <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
            <Route
              path="/mlb"
              element={
                isLoggedIn ? (
                  <Dashboard
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    sport="mlb"
                    propCounts={propCounts}
                    availableSportsbooks={availableSportsbooks}
                    subscriptionType={subscriptionType}
                    sidebarOpen={sidebarOpen}
                    visibleSportsbooks={visibleSportsbooks}
                    toggleSportsbookVisibility={toggleSportsbookVisibility}
                    teams={uniqueTeams}
                  >
                    <OddsPage
                      updateLastUpdated={updateLastUpdated}
                      sport="mlb"
                      favorites={favorites}
                      setFavorites={setFavorites}
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      subscriptionType={subscriptionType}
                      sidebarOpen={sidebarOpen}
                      oddsData={oddsData}
                      filteredData={filteredData}
                      fetchOdds={fetchOdds}
                      applyFilters={applyFilters}
                      sortData={sortData}
                      visibleSportsbooks={visibleSportsbooks}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Dashboard>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/wnba"
              element={
                isLoggedIn ? (
                  <Dashboard
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    sport="wnba"
                    propCounts={propCounts}
                    availableSportsbooks={availableSportsbooks}
                    subscriptionType={subscriptionType}
                    sidebarOpen={sidebarOpen}
                    visibleSportsbooks={visibleSportsbooks}
                    toggleSportsbookVisibility={toggleSportsbookVisibility}
                    teams={uniqueTeams}
                  >
                    <OddsPage
                      updateLastUpdated={updateLastUpdated}
                      sport="wnba"
                      favorites={favorites}
                      setFavorites={setFavorites}
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      subscriptionType={subscriptionType}
                      sidebarOpen={sidebarOpen}
                      oddsData={oddsData}
                      filteredData={filteredData}
                      fetchOdds={fetchOdds}
                      applyFilters={applyFilters}
                      sortData={sortData}
                      visibleSportsbooks={visibleSportsbooks}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Dashboard>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/nfl"
              element={
                isLoggedIn ? (
                  <Dashboard
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    sport="nfl"
                    propCounts={propCounts}
                    availableSportsbooks={availableSportsbooks}
                    subscriptionType={subscriptionType}
                    sidebarOpen={sidebarOpen}
                    visibleSportsbooks={visibleSportsbooks}
                    toggleSportsbookVisibility={toggleSportsbookVisibility}
                    teams={uniqueTeams}
                  >
                    <OddsPage
                      updateLastUpdated={updateLastUpdated}
                      sport="nfl"
                      favorites={favorites}
                      setFavorites={setFavorites}
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      subscriptionType={subscriptionType}
                      sidebarOpen={sidebarOpen}
                      oddsData={oddsData}
                      filteredData={filteredData}
                      fetchOdds={fetchOdds}
                      applyFilters={applyFilters}
                      sortData={sortData}
                      visibleSportsbooks={visibleSportsbooks}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Dashboard>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/nba"
              element={
                isLoggedIn ? (
                  <Dashboard
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    sport="nba"
                    propCounts={propCounts}
                    availableSportsbooks={availableSportsbooks}
                    subscriptionType={subscriptionType}
                    sidebarOpen={sidebarOpen}
                    visibleSportsbooks={visibleSportsbooks}
                    toggleSportsbookVisibility={toggleSportsbookVisibility}
                    teams={uniqueTeams}
                  >
                    <OddsPage
                      updateLastUpdated={updateLastUpdated}
                      sport="nba"
                      favorites={favorites}
                      setFavorites={setFavorites}
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      subscriptionType={subscriptionType}
                      sidebarOpen={sidebarOpen}
                      oddsData={oddsData}
                      filteredData={filteredData}
                      fetchOdds={fetchOdds}
                      applyFilters={applyFilters}
                      sortData={sortData}
                      visibleSportsbooks={visibleSportsbooks}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Dashboard>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/profile"
              element={
                isLoggedIn ? (
                  <ProfilePage setAvatar={setAvatar} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/subscribe" element={isLoggedIn ? <SubscribePage /> : <Navigate to="/login" />} />
            <Route path="/top-props" element={isLoggedIn ? <TopProps /> : <Navigate to="/login" />} />
            <Route path="*" element={<Navigate to={isLoggedIn ? location.pathname : '/login'} />} />
          </Routes>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
