import React, { useState, useEffect } from 'react';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import PaginationControls from './PaginationControls';
import ExpandedRow from './ExpandedRow';
import './StatTable.css';
import { FaSync } from 'react-icons/fa';

const StatTable = ({
  oddsData,
  mlbStatsData,
  wnbaTeamStatsData, // WNBA Team Stats
  wnbaStatsData, // WNBA Player Stats
  nflStatsData,
  showHistoricalData,
  favorites,
  setFavorites,
  subscriptionType,
  currentPage,
  totalPages,
  handlePageChange,
  sortData,
  visibleSportsbooks,
  sport,
  fetchStatsForPlayer,
  filters,
  lineupsData,
  fetchOdds,
  underdogData,
  setCurrentPage,
  onRowExpansion

}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loadingStats, setLoadingStats] = useState({});
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchOdds();
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

  const toggleFavorite = (sport, playerName, prop, overUnder, isFavorite) => {
    const updatedFavorites = isFavorite
      ? favorites[sport].filter((fav) => fav !== playerName + prop + overUnder)
      : [...favorites[sport], playerName + prop + overUnder];
    setFavorites((prevFavorites) => ({ ...prevFavorites, [sport]: updatedFavorites }));
  };

  const handleSortClick = (columnKey) => {
    let newDirection = 'Asc';
    if (sortConfig.key === columnKey) {
      newDirection = sortConfig.direction === 'Asc' ? 'Desc' : null;
    }

    setSortConfig({ key: columnKey, direction: newDirection });
    sortData(columnKey, newDirection);
  };

  const isFiltered = (key) => {
    return filters && (key === filters.sortBy || (key === 'impliedProb' && filters.propType !== 'all'));
  };

  const findStatsForPlayer = (playerName) => {
    if (!playerName || typeof playerName !== 'string') {
      return null; // Return null or handle the case where playerName is not valid
    }
  
    if (sport === 'mlb') {
      return mlbStatsData.find((stats) =>
        stats.playerName?.trim().toLowerCase() === playerName.trim().toLowerCase()
      );
    } else if (sport === 'wnba') {
      const flattenedWnbaStatsData = wnbaStatsData.flat();
      const matchedStats = flattenedWnbaStatsData.filter((stats) =>
        stats.Starters?.trim().toLowerCase() === playerName.trim().toLowerCase()
      );
      return matchedStats;
    } else if (sport === 'nfl') {
      return nflStatsData.find((stats) =>
        stats.playerName?.trim().toLowerCase() === playerName.trim().toLowerCase()
      );
    }
    return null; // Return null if no match is found or sport is not handled
  };
  
  

  const handleRowExpansion = async (index, playerName) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(index)) {
        newExpandedRows.delete(index);
      } else {
        newExpandedRows.add(index);
        fetchStatsForPlayer(playerName);
        setLoadingStats((prevLoading) => ({ ...prevLoading, [index]: true }));
      }
      return newExpandedRows;
    });
  };

  useEffect(() => {
    // setExpandedRows(new Set()); // Reset expanded rows on filter change

    expandedRows.forEach((index) => {
      const playerName = oddsData[index]?.PlayerName;
      if (playerName) {
        const stats = findStatsForPlayer(playerName);
        if (stats) {
          setLoadingStats((prevLoading) => ({ ...prevLoading, [index]: false }));
        }
      }
    });
  }, [filters, oddsData, mlbStatsData, wnbaStatsData]);
  

  return (
    <div className="stat-table-container">
      <div className="refresh-container">
        <button onClick={handleRefresh} className="refresh-button">
          <FaSync className="refresh-icon" /> Refresh
        </button>
      </div>
      {isRefreshing ? (
        <div className="loading-spinner-container">
          <span className="loading-spinner loading loading-lg"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <TableHeader
              sortConfig={sortConfig}
              handleSortClick={handleSortClick}
              visibleSportsbooks={visibleSportsbooks}
              filters={filters}
            />
            <tbody>
              {oddsData.map((odds, index) => {
                const isFavorite = favorites[sport]?.includes(
                  odds.PlayerName + odds.Prop + odds.Over_Under
                );
                const isRowExpanded = expandedRows.has(index);
                const playerStats = findStatsForPlayer(odds.PlayerName);
                const isLoading = loadingStats[index] || false;

                return (
                  <React.Fragment key={`${odds.PlayerName}-${odds.Prop}-${index}`}>
                    <TableRow
                      odds={odds}
                      sport={sport}
                      isFavorite={isFavorite}
                      toggleFavorite={toggleFavorite}
                      showHistoricalData={showHistoricalData}
                      subscriptionType={subscriptionType}
                      visibleSportsbooks={visibleSportsbooks}
                      toggleRowExpansion={() => handleRowExpansion(index, odds.PlayerName)}
                      isRowExpanded={isRowExpanded}
                      playerStats={playerStats}
                      lineupsData={lineupsData}
                      underdogData={underdogData}
                      wnbaTeamStatsData={wnbaTeamStatsData} // Pass WNBA team stats
                    />
                    {isRowExpanded && (
                      <tr key={`expanded-${odds.PlayerName}-${odds.Prop}-${index}`}>
                        <ExpandedRow playerStats={playerStats} isLoading={isLoading} />
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="12">
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default StatTable;
